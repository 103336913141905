import { SaveButton, UseFormReturnType } from '@refinedev/antd'
import { BaseKey, useUpdate } from '@refinedev/core'
import {
  Card,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import {
  IVenueRequirement,
  VenueAreaPreference,
  VenueType,
} from 'interfaces/crm'
import startCase from 'lodash/startCase'
import React, { useEffect, useState } from 'react'
import { SECTION_URL, VENUE_REQUIREMENTS_URL, VENUE_URL } from 'urls'
import { createOptionsFromEnum } from 'utils/common'
import { LeadRequirementCateringOption } from './consts'

export const NewLeadRequirementForm: React.FC<{
  venueReqId?: BaseKey
  refetchVenueReq?: () => void
  leadRefetch?: () => void
  venueData?: IVenueRequirement
  index?: number
  formInstance?: UseFormReturnType<IVenueRequirement>
  leadId?: BaseKey
  isCreating?: boolean
  day?: number
  disabled?: boolean
}> = ({
  venueReqId,
  venueData,
  leadRefetch,
  index,
  formInstance = undefined,
  leadId,
  isCreating = false,
  day,
  disabled
}) => {
  // Methods to either update or create Venue Req.
  const { mutate: updateVenueReq } = useUpdate()

  const getMonth = (value?: string) => {
    if (!value) return ''

    const divided = value.split(' ')
    return divided[divided?.length - 1] || ''
  }

  let months = []
  for (let i = 0; i < 12; i++) {
    months.push(dayjs().add(i, 'month').format('MMM-YY'))
    if (getMonth(venueData?.dates) !== '') {
      months.push(getMonth(venueData?.dates))
    }
  }

  const [date, setDate] = useState<number>(
    Number(
      venueData?.dates
        ?.split(' ')
        .filter((val) => Number(val))
        .join(),
    ),
  )
  const [monthYear, setMonthYear] = useState<any>(getMonth(venueData?.dates))
  const [datesChanged, setDateChanged] = useState<boolean>(false)

  // Options for Venue Types, Regions
  const venueTypes = createOptionsFromEnum(VenueType)
  const venueRegions = createOptionsFromEnum(VenueAreaPreference)

  // Multiple Options are required as the Option are same but dropdown are differenct so,
  // If single option is their than on searching in one dropdown will change options for all
  // dropdowns
  const [breakfastOption, setBreakFastOption] = useState<
    {
      value: string
      label: string
    }[]
  >(
    LeadRequirementCateringOption.map((value) => {
      return {
        value: value,
        label: value,
      }
    }),
  )

  const [lunchOption, setLunchOption] = useState<
    {
      value: string
      label: string
    }[]
  >(
    LeadRequirementCateringOption.map((value) => {
      return {
        value: value,
        label: value,
      }
    }),
  )

  const [highTeaOptions, setHighTeaOption] = useState<
    {
      value: string
      label: string
    }[]
  >(
    LeadRequirementCateringOption.map((value) => {
      return {
        value: value,
        label: value,
      }
    }),
  )

  const [dinnerOption, setDinnerOption] = useState<
    {
      value: string
      label: string
    }[]
  >(
    LeadRequirementCateringOption.map((value) => {
      return {
        value: value,
        label: value,
      }
    }),
  )

  // Handle Updates
  const handleUpdates = (fieldName: string, value: any) => {
    if (!isCreating) {
      updateVenueReq(
        {
          resource: VENUE_REQUIREMENTS_URL,
          id: venueReqId as BaseKey,
          values: {
            [fieldName]: value,
          },
          successNotification: false,
        },
        {
          onSuccess: () => {
            if (fieldName === 'venue') {
              leadRefetch!()
            }
            setDateChanged(false)
          },
        },
      )
    }
  }

  const handleFinish = (values: any) => {
    let date = values.date
    delete values.date
    let month = values.month
    delete values.month
    let tempDate = `${date} ${month}`
    let temp = {
      ...values,
      dates: tempDate,
      area_preference: (values.area_preference as string[])?.join(','),
      venue_type: (values.venue_type as string[])?.join(','),
      lead: leadId,
    }
    formInstance?.onFinish && formInstance?.onFinish(temp)
  }

  useEffect(() => {
    let tempEventData = `${date} ${monthYear}`
    if ((date || monthYear) && datesChanged) {
      handleUpdates('dates', tempEventData)
    }
  }, [date, monthYear, datesChanged])

  return (
    <Form disabled={disabled} size="small" {...formInstance?.formProps} onFinish={handleFinish}>
      <Card
        headStyle={
          isCreating ? { background: '#e1edff' } : { background: '#f8ffec' }
        }
        bodyStyle={
          isCreating ? { background: '#e1edff' } : { background: '#f8ffec' }
        }
        title={
          <Row gutter={24}>
            <Col>
              <Typography.Text>{`Day ${day as number}`}</Typography.Text>
            </Col>
            <Col>
              <Row>
                <Form.Item
                  label="Month"
                  name="month"
                  style={{ marginRight: '8px' }}>
                  <Select
                    style={{
                      width: '150px',
                    }}
                    dropdownMatchSelectWidth={false}
                    defaultValue={monthYear !== '' && monthYear}
                    options={[
                      ...months.map((value) => {
                        return { value: value, label: value }
                      }),
                    ]}
                    onChange={(value: string) => {
                      setMonthYear(value)
                      setDateChanged(true)
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Date"
                  name="date"
                  style={{ marginRight: '8px' }}>
                  <InputNumber
                    size="small"
                    max={31}
                    min={1}
                    defaultValue={date}
                    onChange={(value) => {
                      setDate(value as number)
                      setDateChanged(true)
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Venue"
                  name="venue"
                  style={{ marginRight: '8px' }}>
                  <SelectWithDefault
                    size="small"
                    style={{
                      width: '150px',
                    }}
                    dropdownMatchSelectWidth={false}
                    allowClear
                    useSelectProps={{
                      resource: VENUE_URL,
                      optionLabel: 'name',
                    }}
                    onChange={(value) => {
                      handleUpdates('venue', value)
                    }}
                    defaultValue={venueData?.venue}
                  />
                </Form.Item>
              </Row>
            </Col>
          </Row>
        }>
        <Space direction="vertical">
          <Space
            size="large"
            direction="horizontal"
            style={{ justifyContent: 'space-between' }}>
            <Col>
              <Typography.Title level={5}>Breakfast</Typography.Title>
              <Form.Item name="breakfast_pax">
                <InputNumber
                  defaultValue={venueData?.breakfast_pax}
                  placeholder="PAX"
                  onChange={(value) => handleUpdates('breakfast_pax', value)}
                />
              </Form.Item>
              <Form.Item name="breakfast_event">
                <SelectWithDefault
                  size="small"
                  defaultValue={venueData?.breakfast_event}
                  placeholder="Event"
                  dropdownMatchSelectWidth={false}
                  useSelectProps={{
                    resource: SECTION_URL,
                    optionLabel: 'name',
                  }}
                  allowClear
                  onChange={(value) => {
                    handleUpdates('breakfast_event', value)
                  }}
                />
              </Form.Item>
              <Form.Item name="breakfast_catering">
                <Select
                  options={breakfastOption}
                  allowClear
                  defaultValue={venueData?.breakfast_catering}
                  placeholder="Catering"
                  dropdownMatchSelectWidth={false}
                  showSearch
                  onSearch={(value: string) =>
                    setBreakFastOption(
                      LeadRequirementCateringOption.filter((option) =>
                        option.toLowerCase().includes(value.toLowerCase()),
                      ).map((filteredOption) => {
                        return {
                          value: filteredOption,
                          label: filteredOption,
                        }
                      }),
                    )
                  }
                  onChange={(value) =>
                    handleUpdates('breakfast_catering', value)
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Typography.Title level={5}>Lunch</Typography.Title>
              <Form.Item name="lunch_pax">
                <InputNumber
                  defaultValue={venueData?.lunch_pax}
                  placeholder="PAX"
                  onChange={(value) => handleUpdates('lunch_pax', value)}
                />
              </Form.Item>
              <Form.Item name="lunch_event">
                <SelectWithDefault
                  size="small"
                  defaultValue={venueData?.lunch_event}
                  placeholder="Event"
                  dropdownMatchSelectWidth={false}
                  useSelectProps={{
                    resource: SECTION_URL,
                    optionLabel: 'name',
                  }}
                  allowClear
                  onChange={(value) => {
                    handleUpdates('lunch_event', value)
                  }}
                />
              </Form.Item>
              <Form.Item name="lunch_catering">
                <Select
                  options={lunchOption}
                  allowClear
                  defaultValue={venueData?.lunch_catering}
                  placeholder="Catering"
                  dropdownMatchSelectWidth={false}
                  showSearch
                  onSearch={(value: string) =>
                    setLunchOption(
                      LeadRequirementCateringOption.filter((option) =>
                        option.toLowerCase().includes(value.toLowerCase()),
                      ).map((filteredOption) => {
                        return {
                          value: filteredOption,
                          label: filteredOption,
                        }
                      }),
                    )
                  }
                  onChange={(value) => handleUpdates('lunch_catering', value)}
                />
              </Form.Item>
            </Col>
            <Col>
              <Typography.Title level={5}>High Tea</Typography.Title>
              <Form.Item name="high_tea_pax">
                <InputNumber
                  defaultValue={venueData?.high_tea_pax}
                  placeholder="PAX"
                  onChange={(value) => handleUpdates('high_tea_pax', value)}
                />
              </Form.Item>
              <Form.Item name="high_tea_event">
                <SelectWithDefault
                  size="small"
                  defaultValue={venueData?.high_tea_event}
                  dropdownMatchSelectWidth={false}
                  useSelectProps={{
                    resource: SECTION_URL,
                    optionLabel: 'name',
                  }}
                  placeholder="Event"
                  allowClear
                  onChange={(value) => {
                    handleUpdates('high_tea_event', value)
                  }}
                />
              </Form.Item>
              <Form.Item name="high_tea_catering">
                <Select
                  options={highTeaOptions}
                  allowClear
                  placeholder="Catering"
                  defaultValue={venueData?.high_tea_catering}
                  showSearch
                  dropdownMatchSelectWidth={false}
                  onSearch={(value: string) =>
                    setHighTeaOption(
                      LeadRequirementCateringOption.filter((option) =>
                        option.toLowerCase().includes(value.toLowerCase()),
                      ).map((filteredOption) => {
                        return {
                          value: filteredOption,
                          label: filteredOption,
                        }
                      }),
                    )
                  }
                  onChange={(value) =>
                    handleUpdates('high_tea_catering', value)
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Typography.Title level={5}>Dinner</Typography.Title>
              <Form.Item name="dinner_pax">
                <InputNumber
                  defaultValue={venueData?.dinner_pax}
                  placeholder="PAX"
                  onChange={(value) => handleUpdates('dinner_pax', value)}
                />
              </Form.Item>
              <Form.Item name="dinner_event">
                <SelectWithDefault
                  size="small"
                  defaultValue={venueData?.dinner_event}
                  useSelectProps={{
                    resource: SECTION_URL,
                    optionLabel: 'name',
                  }}
                  dropdownMatchSelectWidth={false}
                  placeholder="Event"
                  allowClear
                  onChange={(value) => {
                    handleUpdates('dinner_event', value)
                  }}
                />
              </Form.Item>
              <Form.Item name="dinner_catering">
                <Select
                  options={dinnerOption}
                  defaultValue={venueData?.dinner_catering}
                  allowClear
                  placeholder="Catering"
                  showSearch
                  dropdownMatchSelectWidth={false}
                  onSearch={(value: string) =>
                    setDinnerOption(
                      LeadRequirementCateringOption.filter((option) =>
                        option.toLowerCase().includes(value.toLowerCase()),
                      ).map((filteredOption) => {
                        return {
                          value: filteredOption,
                          label: filteredOption,
                        }
                      }),
                    )
                  }
                  onChange={(value) => handleUpdates('dinner_catering', value)}
                />
              </Form.Item>
            </Col>
          </Space>
          <Space direction="horizontal">
            <Form.Item label="Accomodation PAX" name="accomodation_pax">
              <InputNumber
                size="small"
                defaultValue={venueData?.accomodation_pax ?? 0}
                onChange={(value) => {
                  handleUpdates('accomodation_pax', value)
                }}
              />
            </Form.Item>
            <Form.Item label="Venue Budget">
              <InputNumber
                min={0}
                defaultValue={venueData?.venue_budget}
                onBlur={(e) => {
                  let value = Number(e.target.value)
                  handleUpdates('venue_budget', value)
                }}
              />
            </Form.Item>

            <Form.Item label="Nearby Accommodation">
              <Switch
                defaultChecked={venueData?.has_nearby_accomodation}
                onChange={(checked: boolean) => {
                  handleUpdates('has_nearby_accomodation', checked)
                }}
              />
            </Form.Item>
          </Space>
          <Form.Item
            label="Region"
            name="area_preference"
            valuePropName="checkedValue">
            <Checkbox.Group
              style={{
                padding: '5px',
                flexDirection: 'row',
              }}
              defaultValue={venueData?.area_preference?.split(',')}
              onChange={(checkedValue) => {
                handleUpdates(
                  'area_preference',
                  checkedValue?.join(',') ?? null,
                )
              }}>
              {venueRegions.map((value, idx) => (
                <Checkbox
                  key={idx}
                  value={value.value}
                  children={<>{startCase(value.label.toLowerCase())}</>}
                />
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            label="Type"
            name="venue_type"
            valuePropName="checkedValue">
            <Checkbox.Group
              style={{
                padding: '5px',
                flexDirection: 'row',
              }}
              defaultValue={venueData?.venue_type?.split(',')}
              onChange={(checkedValue) => {
                handleUpdates('venue_type', checkedValue?.join(',') ?? null)
              }}>
              {venueTypes.map((value, idx) => (
                <Checkbox
                  key={idx}
                  value={value.value}
                  children={<>{value.label}</>}
                />
              ))}
            </Checkbox.Group>
          </Form.Item>
          {formInstance && !disabled && <SaveButton {...formInstance?.saveButtonProps} />}
        </Space>
      </Card>
    </Form>
  )
}
