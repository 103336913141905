import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreateButton, List, PageHeaderProps, useTable } from '@refinedev/antd';
import * as Icons from '@ant-design/icons';

import { Button, Space, Table, Tooltip, Form, Select, Flex, Modal } from 'antd';
import { BaseKey, useDeleteMany, useNavigation, useCustomMutation, CrudFilters, useOne, useApiUrl, useUpdate } from '@refinedev/core';
import { DeleteButton, LogButton } from 'components/button';
import { TableSearchInput } from 'components/input/TableSearchInput';
import { IVenue } from 'interfaces/venue';
import { useState } from 'react';
import { VENUE_URL } from 'urls';
import { getSearchFormInitialValue } from 'utils/filter';
import { TagEditModal } from 'components/input/TagModal';

export const VenueList: React.FC = () => {
  const [form] = Form.useForm();

  const tProps = useTable<IVenue, any, { search: string; tags?: string[] }>({
    onSearch: ({ search, tags }) => {
      const filters: CrudFilters = [];

      if (search) {
        filters.push({
          field: 'search',
          operator: 'eq', // Exact match for search field
          value: search,
        });
      }

      if (tags && tags.length > 0) {
        filters.push({
          field: 'tags',
          operator: 'in', // Match tags with 'in' operator
          value: tags,
        });
      }

      return filters;
    },
    syncWithLocation: true,
  });

  const { edit } = useNavigation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<BaseKey[]>([]);
  const { mutate: deleteManyMutate } = useDeleteMany();
  const { mutate: bulkUpdateTags } = useCustomMutation();
  const { mutate: undeleteMutate } = useUpdate();

  const [isTagModalVisible, setIsTagModalVisible] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { data: venue_tags } = useOne<string[]>({
    resource: VENUE_URL,
    id: 'get_tags',
  });

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deleteMany = () => {
    deleteManyMutate({
      resource: 'package_tool/venues',
      ids: selectedRowKeys,
    });
    setSelectedRowKeys([]);
  };
  const apiUrl = useApiUrl()
  const undeleteVenue = (id: BaseKey) => {
    undeleteMutate({
      resource: 'package_tool/venues',
      id,
      values: { is_deleted: false },
    });
  };
  const handleBulkTagSave = () => {
    bulkUpdateTags({
      url: `${apiUrl}/${VENUE_URL}/add_tags_bulk/`,
      method: 'post',
      values: {
        venue_ids: selectedRowKeys, // Use the correct key for venue IDs
        tags: selectedTags,
      },
    },
    {
      onSettled: () => {
        // Refetch the product list
        tProps.tableQueryResult.refetch()
      },
    }
  );

    setIsTagModalVisible(false);
    setSelectedRowKeys([]);
    setSelectedTags([]); // Clear the modal value

  };

  const pageHeaderProps: PageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && (
        <Button type="primary" onClick={() => setIsTagModalVisible(true)} key="add-tags">
          Add Tags
        </Button>
      ),
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} key="delete" />,
      <LogButton key="log" />,
      <TableSearchInput
        searchFormProps={tProps.searchFormProps}
        key="search"
        intialValues={getSearchFormInitialValue(tProps.filters)}
      />,
      <Form key="tags" form={form} layout="inline" style={{ marginBottom: 0 }}>
        <Flex align="center" gap="small">
          <span style={{ fontWeight: 'bold' }}>Tags:</span>
          <Form.Item name="tags" style={{ marginBottom: 0 }}>
            <Select
              allowClear
              placeholder="Select Tags"
              mode="tags"
              onChange={tProps.searchFormProps.onFinish}
              options={venue_tags?.data?.map((tag: any) => ({
                label: tag,
                value: tag,
              }))}
              style={{ width: 180 }}
            />
          </Form.Item>
        </Flex>
      </Form>,
      <CreateButton key="create" />,
    ],
  };

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        size="small"
        rowSelection={rowSelection}
      >
        <Table.Column<IVenue>
          dataIndex="name"
          title="Name"
          ellipsis
          render={(name: string, record: IVenue) => (
            <a
              onClick={(e) => {
                e.stopPropagation();
                if (!record.is_deleted) {
                  edit(VENUE_URL, record.id);
                }
              }}
              style={{ color: record.is_deleted ? 'gray' : 'blue', cursor: 'pointer' }}
            >
              {name}
            </a>
          )}
        />
        <Table.Column<IVenue>
          dataIndex="tags"
          title="Tags"
          ellipsis
          render={(value, record) => (
            <TagEditModal
              resource={VENUE_URL}
              recordId={record.id}
              tags={record.tags}
              availableTags={venue_tags?.data}
            ></TagEditModal>
          )}
        />
        <Table.Column<IVenue>
          dataIndex="is_deleted"
          title="Is Deleted"
          render={(value: boolean) => (
            <span style={{ color: value ? 'red' : 'green' }}>
              {value ? 'Yes' : 'No'}
            </span>
          )}
        />
        <Table.Column<IVenue>
          dataIndex="id"
          title="Actions"
          ellipsis
          render={(value: BaseKey, record) => (
            <Space align="end" onClick={(e) => e.stopPropagation()}>
              {!record.is_deleted ? (
                <>
                  <Tooltip title="Smart Photo">
                    <Button
                      icon={<FontAwesomeIcon icon={['fas', 'image']} />}
                      size="small"
                      onClick={() =>
                        window.open(`/package_tool/venues/${value}/smart_photos`)
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Venue Portal">
                    <Button
                      icon={<Icons.ShareAltOutlined />}
                      size="small"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_VENUE_URL}/${record.slug}`
                        )
                      }
                    />
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Undelete">
                  <Button
                    icon={<Icons.UndoOutlined />}
                    size="small"
                    onClick={() => undeleteVenue(value)}
                  >
                    Undelete
                  </Button>
                </Tooltip>
              )}
            </Space>
          )}
        />
      </Table>

      <Modal
        title="Add Tags to Selected Venues"
        visible={isTagModalVisible}
        onOk={handleBulkTagSave}
        onCancel={() => {
          setIsTagModalVisible(false);
          setSelectedTags([]); // Clear the tags when modal is closed
        }}
        >
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Add tags"
          options={venue_tags?.data?.map((tag) => ({ label: tag, value: tag }))}
          onChange={(tags) => setSelectedTags(tags)}
        />
      </Modal>
    </List>
  );
};
