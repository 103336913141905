import {
  BaseKey,
  HttpError,
  useInvalidate,
  useList,
  useModal,
  useUpdate,
} from '@refinedev/core'
import { Button, Modal, Popconfirm, Table, Tooltip } from 'antd'
import { IVenueRequirement, IVenueSuggestion } from 'interfaces/crm'
import React, { useContext, useState } from 'react'
import { LEAD_URL } from 'urls'
import * as Icons from '@ant-design/icons'
import { LeadContext } from 'contexts/lead'
import { NewVenueSuggestion } from './VenueSuggestions'

export const VenueRequirements: React.FC<{
  lead_id: BaseKey
  suggestions: IVenueSuggestion[]
  can_not_collect_venue_req: boolean
  name?: string
  phone?: string
  disabled?: boolean
}> = ({ lead_id, suggestions, can_not_collect_venue_req, name, phone, disabled }) => {
  const {
    data: venue_reqs_data,
    isFetching,
    refetch,
  } = useList<IVenueRequirement, HttpError>({
    resource: `${LEAD_URL}/${lead_id}/get_venue_requirements`,
    pagination: {
      mode: 'off',
    },
    filters: [
      {
        field: 'venue_not_decided',
        operator: 'eq',
        value: true,
      },
    ],
  })
  const { show, close, visible } = useModal()
  const [createVenueReq, setCreateVenueReq] = useState<boolean>(false)
  const { lead } = useContext(LeadContext)
  const { mutate: update } = useUpdate()
  const invalidate = useInvalidate()

  const can_not_collect_venue_req_confirm = () => {
    update(
      {
        resource: LEAD_URL,
        id: lead_id,
        values: { could_not_collect_venue_req: true },
      },
      {
        onSuccess: () => {
          invalidate({
            resource: LEAD_URL,
            invalidates: ['list'],
            id: `${lead_id}/get_venue_requirement`,
          })
          invalidate({
            resource: LEAD_URL,
            invalidates: ['list', 'many'],
          })
        },
      },
    )
  }

  return (
    <div>
      <Button
        size="small"
        icon={<Icons.FormOutlined />}
        onClick={() => {
          show()
          refetch()
        }}>
        Venue Suggestions
      </Button>
      {!venue_reqs_data?.data && !lead?.venue && !can_not_collect_venue_req && (
        <Popconfirm
          trigger="hover"
          title="Could not find Venue Requirement?"
          description="Are you sure, you could not find Venue Requirement?"
          onConfirm={can_not_collect_venue_req_confirm}
          okText="Yes"
          cancelText="No">
          <Button
            danger
            type="primary"
            shape="circle"
            icon={<Icons.FrownFilled />}
            size="small"
          />
        </Popconfirm>
      )}
      {can_not_collect_venue_req && (
        <Tooltip title="Lead in progress, without Venue Requirement!!">
          <Icons.WarningOutlined style={{ color: 'red' }} />
        </Tooltip>
      )}
      <Modal
        footer={null}
        open={visible}
        onCancel={() => {
          close()
        }}
        onOk={() => {
          close()
        }}
        title="Venue Suggestions">
        <div>
          <NewVenueSuggestion
            venue_suggestions={suggestions}
            leadId={lead_id}
            name={name || ''}
            phone={phone || ''}
            disabled={disabled}
          />
        </div>
      </Modal>
    </div>
  )
}

export default VenueRequirements
