import { BaseKey, useModal, useUpdate } from '@refinedev/core'
import { Button, Flex, Modal, Select, Tag } from 'antd'
import { useState } from 'react'

export const TagEditModal = ({
  resource,       // Resource name (API endpoint)
  recordId,       // ID of the record being edited
  tags,           // Existing tags
  availableTags,  // All available tags for selection
  onSave,    
  disabled     // Callback to notify parent of changes
}: {
  resource: string
  recordId: BaseKey
  tags?: string[]
  availableTags?: string[]
  onSave?: (updatedTags: string[]) => void
  disabled?: boolean
}) => {
  const { visible, show, close } = useModal()
  const [selectedTags, setSelectedTags] = useState<string[]>(tags || [])
  const { mutate: patch, isLoading: isUpdating } = useUpdate()

  const handleSave = () => {
    patch({
      resource,
      id: recordId,
      values: { tags: selectedTags },
    })
    if (onSave) {
      onSave(selectedTags)
    }
    close()
  }

  return (
    <>
      <Flex gap="4px 0" wrap>
        {tags?.map((tag: any) => (
          <Tag color="magenta" key={tag}>
            {tag}
          </Tag>
        ))}
        <Button disabled={disabled} size="small" type="primary" ghost onClick={show}>
          Add Tag
        </Button>
      </Flex>
      <Modal
        visible={visible}
        onCancel={close}
        footer={
          <Button type="primary" onClick={handleSave} loading={isUpdating}>
            Save
          </Button>
        }
        destroyOnClose>
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Add tags"
          options={availableTags?.map((tag) => ({
            label: tag,
            value: tag,
          }))}
          value={selectedTags}
          onChange={setSelectedTags}
          tokenSeparators={[',']}
        />
      </Modal>
    </>
  )
}
