import { useModalForm } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Divider, Form, InputNumber, Modal, Space, Tag } from "antd";
import { BaseKey } from "@refinedev/core";
import { DateFieldFromNow } from 'components/field/DateFieldFromNow'
import { DatePicker } from 'components/input'

export const CallAttemptInput = ({
  leadId,
  callValue,
  lastCalled,
  name,
  disabled,
}: {
  leadId: BaseKey
  callValue?: number
  lastCalled?: string
  name: string
  disabled?: boolean
}) => {
  const { formProps, modalProps, show } = useModalForm({
    action: 'edit',
    redirect: false,
  })

  return (
    <Space>
      <Space split={<Divider type="vertical" />}>
        {callValue}
        {lastCalled ? (
          <DateFieldFromNow value={lastCalled} />
        ) : (
          <Tag color="red">Not Called</Tag>
        )}
      </Space>
      <Button
        size="small"
        icon={<Icons.EditOutlined />}
        onClick={() => show(leadId)}
        disabled={disabled}
      />

      <>
        <Modal {...modalProps} title="Call Attempts">
          <Form {...formProps} layout="vertical">
            <Form.Item name={name} label="Call Attempt">
              <InputNumber />
            </Form.Item>
            <Form.Item name={`${name}_date_time`} label="Call Attempt Date">
              <DatePicker
                showTime={{ use12Hours: true }}
                format="YYYY-MM-DD hh:mm a"
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    </Space>
  )
}
