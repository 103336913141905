import React, { useContext } from 'react'
import {
  Form,
  Input,
  Modal,
  Space,
  Collapse,
  Switch,
  InputNumber,
  Row,
  Col,
  Select,
} from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { EditPackageContext, EditPackageContextType } from '../Edit'
import { DatePicker } from 'components/input'
import { IPlan } from 'interfaces/shortlistPackage'
import { PLAN_URL } from 'urls'
import { BaseKey } from '@refinedev/core'
type Props = {}

export const EditPackageDetails: React.FC<{
  formProps: any
  modalProps: any
  shortlistPackageId: string
  section: number
}> = ({ formProps, modalProps, shortlistPackageId, section }) => {
  const { editPackageStatus, setEditPackageStatus } = useContext(
    EditPackageContext,
  ) as EditPackageContextType

  const onFinish = async (values: any) => {
    values['package'] = shortlistPackageId
    return formProps.onFinish && formProps.onFinish(values)
  }

  const venueNotDecided = Form.useWatch('venue_not_decided', formProps.form)

  return (
    <div>
      <Modal {...modalProps}>
        <Form
          {...formProps}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...formProps.initialValues,
            section: formProps.initialValues
              ? formProps.initialValues.section.id
              : null,
          }}>
          <Row gutter={16}>
            <Col span={20}>
              <Form.Item
                name={['section']}
                rules={[{ required: true }]}
                label="Event">
                <SelectWithDefault
                  useSelectProps={{
                    resource: `package_tool/sections`,
                    optionLabel: 'name',
                    optionValue: 'id',
                    fetchSize: 100,
                    filters: [
                      { field: 'is_active', operator: 'eq', value: true },
                    ],
                  }}
                  allowOnlyCreate={false}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Order" name={['order']}>
                <Input size="large" prefix="Order: " />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Plan" name="plans">
            <SelectWithDefault<IPlan>
              mode="multiple"
              useSelectProps={{
                resource: PLAN_URL,
                optionLabel: 'name',
                filters: [
                  { field: 'expand', value: 'service', operator: 'eq' },
                  {
                    field: 'sections',
                    value: section,
                    operator: 'eq',
                  },
                ],
              }}
              renderOptions={(plans: any) =>
                plans?.map((plan: any) => (
                  <Select.Option key={plan.id} value={Number(plan.id)}>
                    {plan.name} {plan.service.name}
                  </Select.Option>
                ))
              }
            />
          </Form.Item>
          <Form.Item
            label="Is Alacart"
            name="is_alacart"
            valuePropName="checked"
            initialValue={false}>
            <Switch />
          </Form.Item>
          <Collapse style={{ marginBottom: 25 }}>
            <Collapse.Panel
              header="Time and Venue"
              key="timenvenue"
              forceRender>
              <Form.Item
                name={['date_not_decided']}
                valuePropName="checked"
                label="Date not decided">
                <Switch />
              </Form.Item>
              <Space direction="horizontal" size="large">
                <Form.Item
                  name={['decor_date_time']}
                  label="Decor Start Date and Time">
                  <DatePicker
                    showTime={{ use12Hours: true }}
                    format="YYYY-MM-DD hh:mm a"
                  />
                </Form.Item>
                <Form.Item
                  key={'date_time'}
                  name={['date_time']}
                  rules={[
                    {
                      required: true,
                      message: 'Event Date and Time is required field',
                    },
                  ]}
                  label="Date and Time">
                  <DatePicker
                    showTime={{ use12Hours: true }}
                    format="YYYY-MM-DD hh:mm a"
                  />
                </Form.Item>
                <Form.Item
                  name={['dismantling_start_date_time']}
                  label="Dismantling Start Date and Time">
                  <DatePicker
                    showTime={{ use12Hours: true }}
                    format="YYYY-MM-DD hh:mm a"
                  />
                </Form.Item>
                <Form.Item
                  name={'dismantling_date_time'}
                  label="Dismantling End Date and Time">
                  <DatePicker
                    showTime={{ use12Hours: true }}
                    format="YYYY-MM-DD hh:mm a"
                  />
                </Form.Item>
              </Space>
              <Form.Item
                name={['venue_not_decided']}
                valuePropName="checked"
                label="Venue not decided">
                <Switch />
              </Form.Item>
              <Form.Item
                name={'venue'}
                rules={[
                  {
                    required: !venueNotDecided,
                    message: 'Venue Is required',
                  },
                ]}
                label="Venue">
                <SelectWithDefault
                  useSelectProps={{
                    resource: `package_tool/venues`,
                    optionLabel: 'name',
                    optionValue: 'id',
                  }}
                />
              </Form.Item>

              <Form.Item name={['pax']} label="Pax">
                <InputNumber defaultValue={0} />
              </Form.Item>
              <Form.Item name={['spoc_contact']} label="SPOC Contact">
                <Input />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </Modal>
    </div>
  )
}
