// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import { Icon } from '@ant-design/compatible'
import * as Icons from '@ant-design/icons'

import { CrudFilters, CrudSorting, useOne } from '@refinedev/core'
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Slider,
  Space,
  Tabs,
  Tree,
  TreeDataNode,
  Typography,
} from 'antd'
import { PriceField } from 'components/field'
import { PriceRange } from 'components/input'
import { INTEGER } from 'consts'
import update from 'immutability-helper'
import { IFilter } from 'interfaces/attribute'
import { IProduct, IProductShowcase } from 'interfaces/product'
import { IVenue } from 'interfaces/venue'
import unionBy from 'lodash/unionBy'
import { Key, useEffect, useState } from 'react'
import { useForm as useFormSF } from 'sunflower-antd'
import { PRODUCT_URL } from 'urls'
import { buildOptions, generateFilterName, getFilters } from 'utils/filter'

const FilterOptions = (props: {
  filter: IFilter
  saveAndSubmit: () => void
}) => {
  const [search, setSearch] = useState<string>('')
  return (
    <div>
      {props.filter?.options && props.filter.options?.length > 5 && (
        <Input
          onChange={(e) => setSearch(e.target.value)}
          allowClear
          placeholder={'Search ' + props.filter.attribute.name}
          style={{ marginBottom: 5 }}
        />
      )}
      <Form.Item name={generateFilterName(props.filter)}>
        <Checkbox.Group onChange={props.saveAndSubmit}>
          {buildOptions(
            props.filter?.options?.filter((op) =>
              op.name.toLowerCase().includes(search.toLowerCase()),
            ),
          )}
        </Checkbox.Group>
      </Form.Item>
    </div>
  )
}

const FilterInput: React.FC<{ filter: IFilter; saveAndSubmit: () => void }> = (
  props,
) => {
  switch (props.filter.attribute.attribute_type) {
    case INTEGER:
      return (
        <>
          <Form.Item name={generateFilterName(props.filter)}>
            <PriceRange onSubmit={props.saveAndSubmit} />
          </Form.Item>
        </>
      )
    default:
      return (
        <FilterOptions
          filter={props.filter}
          saveAndSubmit={props.saveAndSubmit}
        />
      )
  }
}

const VenuesCheckBox: React.FC<{
  onChange?: () => void
  spaceVenues?: IVenue[]
  executedVenues?: IVenue[]
  form: any
}> = ({ onChange, spaceVenues = [], executedVenues = [], form }) => {
  const [query, setQuery] = useState('')
  const [venueList, setVenueList] = useState<IVenue[]>()
  const venueState = Form.useWatch('venueState', form)
  const [topVenueList, setTopVenueList] = useState<IVenue[]>()
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    switch (venueState) {
      case 'Space':
        setVenueList(spaceVenues)
        break
      case 'Executed':
        setVenueList(executedVenues)
        break
      default:
        setVenueList(unionBy(spaceVenues, executedVenues, 'id'))
        setTopVenueList(venueList?.slice(0, 10))
        break
    }
  }, [venueState])

  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

  const getName = () => {
    if (venueState === 'Executed') return 'executed_in_venue'
    return 'space_in_venue'
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {spaceVenues && spaceVenues?.length > 5 && (
        <Input
          allowClear
          placeholder="Search Venues..."
          onChange={(e) => setQuery(e.target.value)}
        />
      )}
      <Form.Item name="venueState" initialValue="All">
        <Radio.Group
          options={['All', 'Space', 'Executed']}
          onChange={onChange}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>
      <Form.Item name={getName()}>
        <Checkbox.Group
          onChange={onChange}
          options={venueList
            ?.filter((venue) =>
              venue.name.toLowerCase().includes(query.toLowerCase()),
            )
            .map((venue) => ({
              label: venue.name,
              value: venue.id,
            }))}
        />
      </Form.Item>
    </Space>
  )
}

export const ProductFilter: React.FC<{
  setFilters: (newFilters: CrudFilters) => void
  setSorter: (newSorting: CrudSorting) => void
  setCurrent: (page: number) => void
  categoryNodes?: TreeDataNode[]
  categoryLoading?: boolean
  venues?: IVenue[]
  venuesLoading?: boolean
  executedVenues?: IVenue[]
  executedVenuesLoading?: boolean
  filterData?: IFilter[]
  filterLoading?: boolean
  showInternalUseFilters?: boolean
  productData?: IProduct[] | IProductShowcase[]
  onFinish?: (values: any) => void
  onUndo?: () => void
  stageOptions?: { key: number | string; value: string; label: string }[]
  stageLoading?: boolean
  priceRange?: { min: number; max: number }
}> = ({
  setFilters,
  setSorter,
  setCurrent,
  categoryNodes,
  categoryLoading,
  venues,
  venuesLoading,
  executedVenues,
  executedVenuesLoading,
  filterData,
  filterLoading,
  productData,
  onFinish,
  onUndo,
  priceRange,
  stageOptions,
  stageLoading,
  showInternalUseFilters = false,
}) => {
  const [previousFilters, setPreviousFilters] = useState<any>([])
  const [expandedKeys, setExpandedKeys] = useState<Key[]>()
  const [activePanel, setActivePanel] = useState<string | string[]>()
  const [currentPrice, setCurrentPrice] = useState<number[]>([
    priceRange?.min ?? 0,
    priceRange?.max ?? 0,
  ])

  const [form] = Form.useForm()

  const formSF = useFormSF({
    form: form,
  })

  const saveAndSubmit = async () => {
    setPreviousFilters(
      update(previousFilters, { $push: [form.getFieldsValue(true)] }),
    )
    form.submit()
  }

  const handleUndo = async () => {
    setPreviousFilters(
      update(previousFilters, { $splice: [[previousFilters.length - 1, 1]] }),
    )
    onUndo && onUndo()
    form.submit()
  }

  const [showSearch, setShowSearch] = useState(true)

  const toggleShowSearch = () => {
    setShowSearch(!showSearch)
  }
  const resetAll = () => {
    setPreviousFilters([])
    setSorter([])
    setCurrent(1)
    setCurrentPrice([priceRange?.min ?? 0, priceRange?.max ?? 0])
    form.resetFields()
    form.submit()
  }

  const onSubmit = async (values: any) => {
    const filterValue = { ...previousFilters[previousFilters.length - 1] }
    // get the new filter data from the stack and apply the filters

    switch (values.venueState) {
      case 'Space':
        filterValue.executed_in_venue = undefined
        break
      case 'Executed':
        filterValue.space_in_venue = undefined
        break
    }

    setFilters(
      getFilters(
        [
          {
            field: 'search',
            operator: 'eq',
            value: filterValue.search,
          },
          {
            field: 'category',
            operator: 'in',
            value: filterValue.category?.join(',') ?? '',
          },
          {
            field: 'space_in_venue',
            operator: 'in',
            value: filterValue.space_in_venue?.join(',') ?? '',
          },
          {
            field: 'executed_in_venue',
            operator: 'in',
            value:
              values.venueState === 'All'
                ? filterValue.space_in_venue?.join(',') ?? ''
                : filterValue.executed_in_venue?.join(',') ?? '',
          },
          {
            field: 'is_space',
            operator: 'eq',
            value: values.venueState === 'Space' ? true : null,
          },
          {
            field: 'is_executed',
            operator: 'eq',
            value: values.venueState === 'Executed' ? true : null,
          },
          {
            field: 'is_internal',
            operator: 'eq',
            value: filterValue.is_internal,
          },
          {
            field: 'is_published',
            operator: 'eq',
            value: filterValue.is_published,
          },
          {
            field: 'is_deleted',
            operator: 'eq',
            value: filterValue.is_deleted,
          },
          {
            field: 'stage',
            operator: 'eq',
            value: filterValue.stage,
          },
          {
            field: 'tags__name',
            operator: 'in',
            value: filterValue.tags?.join(','),
          },
        ],
        filterValue,
        filterData,
      ),
    )

    setSorter([{ field: values.sort, order: 'asc' }])

    onFinish && onFinish(filterValue)

    // reset the form fields and then set the fields from the previousFilters stack
    formSF.form.resetFields()
    formSF.form.setFieldsValue({ ...filterValue, sort: values.sort })

    setCurrent(1)
  }

  const { data: product_tags } = useOne<string[]>({
    resource: PRODUCT_URL,
    id: 'get_tags',
  })

  useEffect(() => {
    setCurrentPrice([priceRange?.min ?? 0, priceRange?.max ?? 0])
  }, [priceRange])

  return (
    <Form layout="vertical" {...formSF.formProps} onFinish={onSubmit}>
      <Row justify="space-between" align="middle">
        <Typography.Title
          level={3}
          style={{ fontFamily: 'Gilroy', fontWeight: '500' }}>
          Filters
        </Typography.Title>
        <Row justify="space-between" style={{ padding: '20px 0' }}>
          <Space direction="horizontal">
            {!!previousFilters.length && (
              <Button
                onClick={handleUndo}
                icon={<Icons.ArrowLeftOutlined />}
                shape="circle"
                style={{
                  background: '#FEF8E9',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  border: 'none',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                  display: 'inline-flex',
                }}
              />
            )}
            <Button
              onClick={resetAll}
              icon={<Icons.SyncOutlined />}
              style={{
                background: '#FEF8E9',
                borderRadius: '50%',
                overflow: 'hidden',
                border: 'none',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 10,
                display: 'inline-flex',
              }}
            />
            <Button
              onClick={toggleShowSearch}
              icon={
                showSearch ? <Icons.SearchOutlined /> : <Icons.CloseOutlined />
              }
              shape="circle"
              style={{
                background: '#F0EAF9',
                borderRadius: '50%',
                overflow: 'hidden',
                border: 'none',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 10,
                display: 'inline-flex',
              }}
            />
          </Space>
        </Row>
      </Row>
      <Form.Item name="search" hidden={showSearch}>
        <Input.Search
          hidden={showSearch}
          placeholder="Name, SKU"
          onSearch={saveAndSubmit}
          style={{ fontFamily: 'Gilroy' }}
          enterButton
        />
      </Form.Item>
      <Form.Item name="sort" initialValue="-created_at">
        <Select
          className="showcase-sort-select"
          defaultValue="-created_at"
          style={{ width: '100%' }}
          onChange={() => form.submit()}
          dropdownStyle={{ fontFamily: 'Gilroy' }}
          suffixIcon={
            <>
              <Divider
                type="vertical"
                style={{ borderLeft: '1.5px solid grey' }}></Divider>
              <Typography>Sort By</Typography>
            </>
          }
          options={[
            { value: '-created_at', label: 'Newest' },
            { value: 'price', label: 'Price(Low to High)' },
            { value: '-price', label: 'Price(High to Low)' },
            { value: '-add_to_package_count', label: 'Popularity' },
          ]}
        />
      </Form.Item>
      <Form.Item name="tags" label="Tags">
          <Select
            allowClear
            placeholder="enter tags"
            mode='tags'
            onChange={saveAndSubmit}
            options={product_tags?.data?.map((tag: any) => ({
              label: tag,
              value: tag,
            }))}
          />
        </Form.Item>
      <Collapse
        defaultActiveKey={['category']}
        onChange={(value) => setActivePanel(value)}
        bordered={false}
        accordion={true}
        expandIconPosition="right"
        style={{
          border: 'none',
          backgroundColor: 'white',
          marginTop: '10px',
        }}>
        <Collapse.Panel header="Category" key="category">
          {categoryLoading ? (
            <Icons.LoadingOutlined />
          ) : (
            <Form.Item
              name="category"
              valuePropName="checkedKeys"
              trigger="onCheck">
              <Tree
                checkable
                selectable={false}
                treeData={categoryNodes}
                expandedKeys={expandedKeys}
                onExpand={(ek) => setExpandedKeys(ek)}
                onCheck={saveAndSubmit}
              />
            </Form.Item>
          )}
        </Collapse.Panel>
        <Collapse.Panel header="Venue" key="venue">
          {venuesLoading || executedVenuesLoading ? (
            <Icons.LoadingOutlined />
          ) : (
            <VenuesCheckBox
              onChange={saveAndSubmit}
              spaceVenues={venues}
              executedVenues={executedVenues}
              form={form}
            />
          )}
        </Collapse.Panel>
        <Collapse.Panel header="Price" key="price">
          {categoryLoading ? (
            <Icons.LoadingOutlined />
          ) : (
            <>
              From:{' '}
              <PriceField
                value={currentPrice[0]}
                style={{ fontWeight: 'bold' }}
              />
              <Form.Item
                name="price"
                noStyle
                initialValue={[priceRange?.min, priceRange?.max]}>
                <Slider
                  range
                  min={priceRange?.min ?? 0}
                  max={priceRange?.max}
                  step={10}
                  onChange={setCurrentPrice}
                  onAfterChange={saveAndSubmit}
                />
              </Form.Item>
              Till:{' '}
              <PriceField
                value={currentPrice[1]}
                style={{ fontWeight: 'bold' }}
              />
            </>
          )}
        </Collapse.Panel>
        {showInternalUseFilters && (
          <>
            <Collapse.Panel header="Internal Products" key="internal">
              <Form.Item name="is_internal" initialValue={null}>
                <Radio.Group onChange={saveAndSubmit}>
                  <Space direction="vertical">
                    <Radio value={null}>Include Internal</Radio>
                    <Radio value={false}>Exclude Internal</Radio>
                    <Radio value={true}>Only Internal</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel header="Published Products" key="published">
              <Form.Item name="is_published" initialValue={null}>
                <Radio.Group onChange={saveAndSubmit}>
                  <Space direction="vertical">
                    <Radio value={null}>Include Published</Radio>
                    <Radio value={false}>Exclude Published</Radio>
                    <Radio value={true}>Only Published</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel header="Stage" key="stage">
              <Form.Item name="stage" initialValue={null}>
                {stageLoading ? (
                  <Icons.LoadingOutlined />
                ) : (
                  <Radio.Group onChange={saveAndSubmit}>
                    <Space direction="vertical">
                      <Radio value={null}>All</Radio>
                      {stageOptions?.map((option) => (
                        <Radio key={option.key} value={option.value}>
                          {option.label}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                )}
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel header="Deleted Products" key="deleted">
              <Form.Item name="is_deleted" initialValue={null}>
                <Radio.Group onChange={saveAndSubmit}>
                  <Space direction="vertical">
                    <Radio value={null}>Include Deleted</Radio>
                    <Radio value={false}>Exclude Deleted</Radio>
                    <Radio value={true}>Only Deleted</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Collapse.Panel>
          </>
        )}
        {filterLoading ? (
          <Icons.LoadingOutlined />
        ) : (
          filterData?.map((filter) => {
            return (
              <Collapse.Panel
                header={filter.attribute.name}
                key={filter.attribute.name}>
                {filterLoading ? (
                  <Icons.LoadingOutlined />
                ) : (
                  <FilterInput saveAndSubmit={saveAndSubmit} filter={filter} />
                )}
              </Collapse.Panel>
            )
          })
        )}
      </Collapse>
    </Form>
  )
}
