import React from 'react'

import { Collapse, Form, Input, InputNumber, Modal, Space, Switch } from 'antd'
import { DatePicker } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { SECTION_URL } from 'urls'
import { BaseKey } from '@refinedev/core'
type Props = {}

export const CreatePackageDetails: React.FC<{
  formProps: any
  modalProps: any
  shortlistPackageId: string
  projectId: BaseKey
}> = ({ formProps, modalProps, shortlistPackageId, projectId }) => {
  const onFinish = async (values: any) => {
    values['package'] = shortlistPackageId
    return formProps.onFinish && formProps.onFinish(values)
  }

  const venueNotDecided = Form.useWatch('venue_not_decided', formProps.form)

  return (
    <div>
      <Modal
        {...modalProps}
        onCancel={() => {
          formProps.form.resetFields()
          modalProps.onCancel()
        }}>
        <Form {...formProps} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name={['section']}
            rules={[{ required: true }]}
            label="Event">
            <SelectWithDefault
              useSelectProps={{
                resource: SECTION_URL,
                optionLabel: 'name',
                optionValue: 'id',
                filters: [
                  { field: 'is_active', operator: 'eq', value: true },
                ],
              }}
              placeholder="Event"
              dropdownMatchSelectWidth={false}
            />
          </Form.Item>
          <Collapse style={{ marginBottom: 25 }}>
            <Collapse.Panel
              header="Time and Venue"
              key="timenvenue"
              forceRender>
              <Form.Item
                name={['date_not_decided']}
                valuePropName="checked"
                label="Date not decided">
                <Switch />
              </Form.Item>
              <Space direction="horizontal" size="large">
                <Form.Item
                  name={['date_time']}
                  rules={[
                    {
                      required: true,
                      message: 'Event Date and Time is required field',
                    },
                  ]}
                  label="Date and Time">
                  <DatePicker
                    showTime={{ use12Hours: true }}
                    format="YYYY-MM-DD hh:mm a"
                  />
                </Form.Item>
                <Form.Item
                  name={['dismantling_date_time']}
                  label="Dismantling End Date and Time">
                  <DatePicker
                    showTime={{ use12Hours: true }}
                    format="YYYY-MM-DD hh:mm a"
                  />
                </Form.Item>
                <Form.Item
                  name={['decor_date_time']}
                  label="Decor Start Date and Time">
                  <DatePicker
                    showTime={{ use12Hours: true }}
                    format="YYYY-MM-DD hh:mm a"
                  />
                </Form.Item>
              </Space>
              <Form.Item
                name={['venue_not_decided']}
                valuePropName="checked"
                label="Venue not decided">
                <Switch />
              </Form.Item>
              <Form.Item
                name={['venue']}
                rules={[
                  {
                    required: !venueNotDecided,
                  },
                ]}
                label="Venue">
                <SelectWithDefault
                  useSelectProps={{
                    resource: `package_tool/venues`,
                    optionLabel: 'name',
                    optionValue: 'id',
                  }}
                />
              </Form.Item>

              <Form.Item name={['pax']} label="Pax">
                <InputNumber defaultValue={0} />
              </Form.Item>
              <Form.Item name={['spoc_contact']} label="SPOC Contact">
                <Input />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </Modal>
    </div>
  )
}
