import React, { useRef, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
//@ts-ignore
import * as Emoji from 'quill-emoji'
import { markdownToHtml, htmlToMarkdown } from './Parser'

import 'react-quill/dist/quill.snow.css'
import 'quill-emoji/dist/quill-emoji.css'

// Register the emoji module
Quill.register('modules/emoji', Emoji)

export interface EditorProps {
  value?: string
  onChange?: (changes: string) => void
}

const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, false] }],
  ['bold', 'italic', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['emoji'],
  ['clean'],
]

export default function Editor(props: EditorProps) {
  const [value, setValue] = useState<string>(markdownToHtml(props.value || ''))
  const reactQuillRef = useRef<ReactQuill>(null)

  const onChange = (content: string) => {
    setValue(content)

    if (props.onChange) {
      props.onChange(htmlToMarkdown(content))
    }
  }

  return (
    <ReactQuill
      ref={reactQuillRef}
      theme="snow"
      placeholder="Start writing..."
      modules={{
        toolbar: {
          container: TOOLBAR_OPTIONS,
        },
        'emoji-toolbar': true,
        'emoji-textarea': false,
        'emoji-shortname': true,
      }}
      value={value}
      onChange={onChange}
    />
  )
}
