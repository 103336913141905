import { BaseKey, HttpError, useList, useOne } from '@refinedev/core'
import { Card, Divider, Spin, Typography } from 'antd'
import { ILead, IVenueRequirement } from 'interfaces/crm'
import React, { useState } from 'react'
import { LEAD_URL, VENUE_REQUIREMENTS_URL } from 'urls'
import { LeadServices } from '../../ServicesModal'
import { VenueReqEventForm } from './VenueReqEventForm'
import { VenueReqProjectDetails } from './VenueReqProjectDetails'
import { useForm } from '@refinedev/antd'
import { NewLeadRequirementForm } from './LeadRequirementForm'

export const NewVenueRequirementForm: React.FC<{
  leadName: string
  lead: ILead
  disabled?: boolean
}> = ({ leadName, lead, disabled }) => {
  const [addVenueReq, setAddVenueReq] = useState<boolean>(false)
  const {
    data: venueReqData,
    isFetching,
    refetch,
  } = useList<IVenueRequirement, HttpError>({
    resource: `${LEAD_URL}/${lead?.id}/get_venue_requirements/`,
    pagination: {
      mode: 'off',
    },
    queryOptions: {
      enabled: Boolean(lead?.id),
    },
  })

  const {
    data: leadData,
    refetch: leadRefetch,
    isLoading: leadLoading,
  } = useOne<ILead>({
    resource: LEAD_URL,
    id: lead?.id as BaseKey,
    queryOptions: {
      enabled: Boolean(lead?.id),
    },
  })

  const leadReqFormInstance = useForm<IVenueRequirement>({
    resource: VENUE_REQUIREMENTS_URL,
    action: 'create',
    redirect: false,

    onMutationSuccess: () => {
      refetch()
      leadReqFormInstance.form.resetFields()
    },
  })

  return (
    <>
      <Typography.Text strong style={{ fontSize: 18 }}>
        {leadName}
      </Typography.Text>
      <Divider>Project Details</Divider>

      <VenueReqProjectDetails
        leadData={leadData}
        leadId={lead.id}
        leadRefetch={leadRefetch}
        leadDataLoading={leadLoading}
        disabled={disabled}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          maxHeight: '60vh',
        }}>
        <Divider>Services</Divider>
        <LeadServices disabled={disabled} leadId={lead.id} isLeadRequirementService />
        <Divider>Events({venueReqData?.total ?? 0})</Divider>
        {isFetching ? (
          <Spin />
        ) : (
          <>
            <Card
              size="small"
              title="Add New Day"
              headStyle={{ background: '#e1edff' }}
              bodyStyle={{ background: '#e1edff' }}>
              <NewLeadRequirementForm
                isCreating
                formInstance={leadReqFormInstance}
                day={(venueReqData?.total ?? 0) + 1}
                leadId={lead.id}
                disabled={disabled}
              />
            </Card>
            <Divider />
            {venueReqData?.data?.map((value, idx) => (
              <div key={idx}>
                <Card
                  key={idx}
                  size="small"
                  title={
                    value?.events === null && (
                      <Typography.Text
                        strong
                        style={{
                          fontSize: 16,
                        }}>
                        {value.event_name}
                      </Typography.Text>
                    )
                  }
                  headStyle={{ background: '#f8ffec' }}
                  bodyStyle={{ background: '#f8ffec' }}>
                  <VenueReqEventForm
                    index={idx}
                    day={venueReqData?.total - idx}
                    venueReqId={value.id}
                    venueData={value}
                    leadId={lead?.id}
                    leadRefetch={leadRefetch}
                    disabled={disabled}
                  />
                </Card>
                {<Divider dashed />}
              </div>
            ))}
          </>
        )}
      </div>
    </>
  )
}
