import { Select } from "antd";
import { BaseKey, useUpdate } from "@refinedev/core";
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IStaff } from 'interfaces/staff'
import { LEAD_SERVICE_URL, LEAD_URL, STAFF_URL } from 'urls'

export const ConsultantSelect = ({
  leadId,
  fieldName,
  value,
  is_lead_service,
  refetch,
  disabled
}: {
  leadId: BaseKey
  fieldName: string
  value?: any
  is_lead_service?: boolean
  refetch?: any
  disabled?: boolean
}) => {
  const { mutate } = useUpdate()
  const handleChange = (value: any) => {
    mutate(
      {
        resource: is_lead_service ? LEAD_SERVICE_URL : LEAD_URL,
        id: leadId,
        values: {
          [fieldName]: value,
        },
      },
      {
        onSuccess(data, variables, context) {
          refetch && refetch();
        },
      })

  }
  return (
    <SelectWithDefault<IStaff>
      useSelectProps={{ resource: STAFF_URL }}
      dropdownMatchSelectWidth={false}
      allowClear
      value={value}
      onChange={handleChange}
      onClear={() => handleChange(null)}
      size="small"
      disabled={disabled}
      renderOptions={(staffs) =>
        staffs?.map((staff) => (
          <Select.Option key={staff.id} value={staff.id}>
            {staff.first_name} {staff.last_name}
          </Select.Option>
        ))
      }
    />
  )
}
