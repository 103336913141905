import { Cascader, Modal, Table } from 'antd'
import {
  BaseKey,
  useApiUrl,
  useCreate,
  useCustom,
  useDelete,
  useOne,
} from '@refinedev/core'
import { LeadContext } from 'contexts/lead'
import { ITracker, IVenueRequirement } from 'interfaces/crm'
import { useContext } from 'react'

import { LEAD_SERVICE_STATUS_URL, LEAD_SERVICE_URL, LEAD_URL } from 'urls'

import { BookingProbability } from './BookingProbability'

import { ConsultantSelect } from './ConsultantSelect'
import { MeetingModal } from './MeetingModal'
import { NotesInput } from './NotesInput'

export const ServiceStatusTable: React.FC<{
  lead_id: BaseKey
  budget: number
  could_not_collect_budget: boolean
  could_not_collect_venue_req: boolean
  disabled?: boolean
}> = ({
  lead_id,
  budget,
  could_not_collect_budget,
  could_not_collect_venue_req,
  disabled
}) => {
  const { mutate, isLoading: postLoading } = useCreate()
  const { mutate: destroy, isLoading: destroyLoading } = useDelete()
  const apiUrl = useApiUrl()

  const { lead, refetch: refetchLead } = useContext(LeadContext)

  const {
    data: service,
    isLoading: leadServicesLoading,
    isFetching: leadServicesFetching,
    refetch,
  } = useOne<any>({
    resource: LEAD_URL,
    id: `${lead_id}/get_services`,
  })

  const { data: venue_req_data } = useOne<IVenueRequirement>({
    resource: LEAD_URL,
    id: `${lead_id}/get_venue_requirement`,
  })

  const missingVenueReqError = () => {
    Modal.error({
      title: 'OOPs Missing Venue Requirement',
      content: 'Cannot proceed if venue requirement info is missing :(',
      width: 30,
    })
  }

  const missingBudgetError = () => {
    Modal.error({
      title: 'OOPs Missing Budget',
      content: 'Cannot proceed if without Budget :(',
      width: 30,
    })
  }

  const StatusCascader = ({
    statuses,
    record,
  }: {
    statuses: any
    record: any
  }) => {
    const { data: serviceStatuses, isLoading } = useCustom<ITracker[]>({
      url: `${apiUrl}/${LEAD_SERVICE_URL}/status_list/?service_id=${record.service.id}&`,
      method: 'get',
    })

    return (
      <Cascader
        placeholder="Status"
        displayRender={(label) => label[0]}
        disabled={disabled}
        value={
          statuses?.length
            ? ([statuses[0].status, statuses[0].reason] as any)
            : null
        }
        loading={
          isLoading || leadServicesFetching || postLoading || destroyLoading
        }
        allowClear={!!statuses?.length}
        onClear={() =>
          destroy(
            {
              resource: LEAD_SERVICE_STATUS_URL,
              id: (statuses as any)[0].id,
            },
            {
              onSuccess: () => {
                refetch()
                refetchLead!()
              },
            },
          )
        }
        style={{ width: '100%' }}
        options={serviceStatuses?.data?.map((status) => ({
          label: status.name,
          value: status.name,
          children: status.options?.map((option) => ({
            label: option,
            value: option,
          })),
        }))}
        onChange={(value) => {
          if (
            (value[0] === 'Prospect' || value[0] === 'Not Prospect') &&
            !lead?.venue &&
            !venue_req_data?.data.lead &&
            !could_not_collect_venue_req
          ) {
            missingVenueReqError()
          } else if (
            (value[0] === 'Prospect' || value[0] === 'Not Prospect') &&
            budget === 0 &&
            !could_not_collect_budget
          ) {
            missingBudgetError()
          } else {
            mutate(
              {
                resource: LEAD_SERVICE_URL,
                values: {
                  lead: lead_id,
                  service: record.service.id,
                  status: value[0],
                  reason: value[1] ?? '',
                },
              },
              {
                onSuccess: () => {
                  refetch()
                  refetchLead!()
                },
              },
            )
          }
        }}
      />
    )
  }

  const columns = [
    {
      title: 'Service',
      dataIndex: ['service', 'name'],
      key: 'service',
      width: 200,
    },
    {
      title: 'Consultant',
      dataIndex: 'consultant',
      key: 'consultant',
      width: 200,
      render: (value: BaseKey, record: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ConsultantSelect
            leadId={record.id}
            value={value}
            fieldName="consultant"
            is_lead_service={true}
            refetch={refetch}
            disabled={disabled}
          />
        </div>
      ),
    },
    {
      title: 'Status',
      key: 'state',
      dataIndex: ['statuses'],
      width: 200,
      render: (statuses: any, record: any) => (
        <StatusCascader statuses={statuses} record={record} />
      ),
    },
    {
      title: 'Booking Probability',
      dataIndex: 'booking_probability',
      key: 'booking_probability',
      width: 300,
      render: (value: any, record: any) => (
        <BookingProbability
          lead={record}
          is_lead_service={true}
          refetch={refetch}
          disabled={disabled}
        />
      ),
    },
    {
      title: 'Notes',
      key: 'notes',
      width: 100,
      render: (value: BaseKey, record: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <NotesInput disabled={disabled} leadServiceId={record.id} withLabel={false} />
        </div>
      ),
    },
    {
      title: 'Meetings',
      ellipsis: true,
      width: 200,
      render: (_: any, record: any) => (
        <MeetingModal
          lead_service_id={record.id}
          service={record.service.name}
          disabled={disabled}
        />
      ),
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        size="small"
        loading={leadServicesLoading}
        dataSource={service?.data}
        pagination={false}
        style={{ width: '1500px' }}
      />
    </>
  )
}
