import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, Input, Space, InputNumber, Select } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { BaseKey, useUpdate } from '@refinedev/core'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { TDS_SECTION } from 'urls'
import { TdsRecord } from 'interfaces/payout'

// Define the TDS record type
type WithoutTdsAmount = Omit<TdsRecord, 'tds_amount'>

const AddTdsModal: React.FC<{
  purchase_order: number
  payout: BaseKey
  tds_records: any
  refetch: any
}> = ({ purchase_order, payout, tds_records, refetch }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [initialRecords, setInitialRecords] = useState<TdsRecord[]>([])
  const [form] = Form.useForm()
  const { mutate: patch, isLoading: isUpdating } = useUpdate()

  // Open the modal
  const showModal = (): void => {
    setIsModalOpen(true)
  }

  useEffect(() => {
    const newList: WithoutTdsAmount[] = (tds_records as any).map(
      (tds_record: any) => {
        const { tds_amount, ...rest } = tds_record
        return rest
      },
    )

    setInitialRecords(newList)
    form.setFieldsValue({
      tdsRecords: newList,
    })
  }, [tds_records, isModalOpen])

  // Close the modal and reset the form
  const handleCancel = (): void => {
    setIsModalOpen(false)
    form.resetFields()
  }
  // Handle form submission
  const handleFinish = (values: { tdsRecords: TdsRecord[] }): void => {
    patch(
      {
        resource: 'package_tool/payouts',
        id: payout,
        values: { tds_records: values.tdsRecords },
      },
      {
        onSuccess: () => {
          refetch()
          setIsModalOpen(false)
        },
      },
    )
    form.resetFields()
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Add TDS
      </Button>
      <Modal
        title="Add TDS Records"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}>
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Form.List name="tdsRecords">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'tds_section']}
                      rules={[
                        { required: true, message: 'TDS Section is required' },
                      ]}>
                      <SelectWithDefault
                        dropdownMatchSelectWidth={false}
                        useSelectProps={{
                          resource: TDS_SECTION,
                          optionLabel: 'name',
                        }}
                        renderOptions={(items: any) =>
                          items?.map((item: any) => (
                            <Select.Option key={item.section} value={item.id}>
                              {`${item.section} (${item.rate})`} -
                            </Select.Option>
                          ))
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'taxable_amount']}
                      rules={[
                        {
                          required: true,
                          message: 'Taxable Amount is required',
                        },
                      ]}>
                      <InputNumber placeholder="Taxable Amount" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() =>
                      add({
                        tds_section: '',
                        taxable_amount: 0,
                        purchase_order: purchase_order,
                        payout: payout,
                      })
                    }
                    block
                    icon={<PlusOutlined />}>
                    Add TDS Record
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddTdsModal
