import { BaseKey } from '@refinedev/core'
import { Form, FormProps, Input, InputNumber, Select, Space } from 'antd'
import { SingleImageUpload } from 'components/input'
import { OverallPackageType } from 'interfaces/venue'
import { createOptionsFromEnum } from 'utils/common'
import { handleBase64Image } from 'utils/form'

export const OverallPackageForm = (props: {
  formProps: FormProps
  venueId: BaseKey
}) => {
  return (
    <Form
      {...props.formProps}
      layout="vertical"
      onFinish={async (values) => {
        const finalImage = await handleBase64Image(values.cover_image)
        const finalResponse = { ...values, venue: props.venueId }
        if (finalImage.length) finalResponse['thumbnail'] = finalImage
        props.formProps.onFinish!(finalResponse)
      }}>
      <Form.Item name="package_type" label="Package Type">
        <Select options={createOptionsFromEnum(OverallPackageType)} />
      </Form.Item>
      <SingleImageUpload
        label="Thumbnail"
        initialImageUrl={props.formProps.initialValues?.thumbnail}
      />
      <Form.Item name="subheading" label="Sub Heading">
        <Input />
      </Form.Item>
      <Form.Item name="project" label="PID">
          <InputNumber />
        </Form.Item>
      <Space>
        <Form.Item name="total" label="Total">
          <InputNumber />
        </Form.Item>
        <Form.Item name="venue_price" label="Venue Price">
          <InputNumber />
        </Form.Item>
        <Form.Item name="decor_price" label="Decor Price">
          <InputNumber />
        </Form.Item>
        <Form.Item name="catering_price" label="Catering Price">
          <InputNumber />
        </Form.Item>
        <Form.Item name="photography_price" label="Photography Price">
          <InputNumber />
        </Form.Item>
      </Space>
    </Form>
  )
}
