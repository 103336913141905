import { Form, Input, Select, Space, Switch, Upload } from 'antd'
import {
  BOOLEAN,
  COLOR,
  IMAGE,
  MULTI_OPTIONS,
  MULTI_SELECT,
  SELECT,
} from 'consts'
import {
  IAttribute,
  IAttributeOption,
  IAttributeValue,
} from 'interfaces/attribute'
import { ReactElement, useState } from 'react'
const { TextArea } = Input

const ImageUploadField: React.FC<{ value?: any; onChange?: Function }> = (
  props,
) => {
  const [fileList, setFileList] = useState(props.value)
  return (
    <Upload.Dragger
      multiple
      beforeUpload={() => false}
      fileList={fileList}
      listType="picture-card"
      onChange={({ fileList: newFileList }) => {
        setFileList(newFileList)
        props.onChange && props.onChange(newFileList)
      }}>
      Drop Image
    </Upload.Dragger>
  )
}

/**
 * Method identifies and returns the input relevant to the type
 */
export const getInputFromAttributeType = (
  type: string,
  options: [IAttributeOption],
): ReactElement => {
  switch (type) {
    case SELECT:
    case MULTI_SELECT:
    case COLOR:
      return (
        <Select
          mode={MULTI_OPTIONS.includes(type) ? 'multiple' : undefined}
          optionFilterProp={'label'}
          showSearch
          allowClear
          >
          {options.map((choice: IAttributeOption) => (
            <Select.Option
              key={choice.id}
              value={choice.id}
              label={choice.name}>
              <Space>
                {choice.value && (
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      background: choice.value,
                    }}></div>
                )}
                {choice.name}
              </Space>
            </Select.Option>
          ))}
        </Select>
      )
    case BOOLEAN:
      return <Switch />
    case IMAGE:
      return <ImageUploadField />
    default:
      return <Input />
  }
}

const getValue = (attr_details: IAttribute, value?: IAttributeValue) => {
  switch (attr_details.attribute_type) {
    case SELECT:
    case MULTI_SELECT:
    case COLOR:
      return value?.value_options.map((op: any) => op.option)
    case IMAGE:
      return value?.images.map((image) => ({
        uid: image.id,
        name: 'test.png',
        url: image.image,
        thumbUrl: image.image,
        status: 'done',
      }))
    default:
      return value?.value
  }
}

/**
 * Component dynamically builds the required input for the given attribute
 * and provides the initial value if present
 */
export const DynamicInput = ({
  attr_details,
  values,
}: {
  attr_details: IAttribute
  values?: IAttributeValue[]
}) => {
  let input: ReactElement = <TextArea />

  input = getInputFromAttributeType(
    attr_details?.attribute_type,
    attr_details.options,
  )
  const value = values?.find(
    (attr: IAttributeValue) => attr.attribute === attr_details.id,
  )
  return (
    <Form.Item
      label={attr_details.name}
      //name should be in this format -> generated_<existing value id>_<base attribute id>_<attribute type>
      name={`generated_${value?.id}_${attr_details.id}_${attr_details.attribute_type}`}
      initialValue={getValue(attr_details, value)}
      valuePropName={
        attr_details?.attribute_type === BOOLEAN ? 'checked' : undefined
      }
      key={attr_details.id}>
      {input}
    </Form.Item>
  )
}
