import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Edit,
  ListButton,
  PageHeaderProps,
  RefreshButton,
  useModalForm,
} from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { Button, Form, Input, Modal, Select, Space } from 'antd'
import { LogButton } from 'components/button'
import { useForm } from 'components/hooks/useForm'
import { IVenue } from 'interfaces/venue'
import { VenueForm } from './Form'
import { AutoGeneratedPasswordInput } from 'components/input'
import { useState } from 'react'
import { generatePassword } from 'utils/common'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IStaff } from 'interfaces/staff'
import { STAFF_URL } from 'urls'

export const VenueEdit: React.FC = () => {
  const useFormProps = useForm<IVenue>({ redirect: 'edit' })
  const [generatedPswd, setPassword] = useState<string>('')
  const [isAddingNewUser, setIsAddingNewUser] = useState<boolean>(false)
  const handlePassword = () => setPassword(generatePassword())
  const {
    formProps: addMemberFormProps,
    modalProps,
    show,
  } = useModalForm({
    resource: `package_tool/venues/${useFormProps.id as string}/add_member`,
    action: 'create',
    redirect: false,
    onMutationSuccess: () => {
      useFormProps.queryResult?.refetch()
      setIsAddingNewUser((prev) => false)
    },
  })

  const pageHeaderProps: PageHeaderProps = {
    extra: [
      <LogButton key="log" />,
      <Button
        key={'member'}
        onClick={() => show()}
        icon={<Icons.UserAddOutlined />}>
        Add Member
      </Button>,
      <Button
        key="go to smart photo"
        icon={<Icons.ShareAltOutlined />}
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_VENUE_URL}/${useFormProps.queryResult?.data?.data.slug}`,
          )
        }>
        {' '}
        Venue Portal
      </Button>,
      <Button
        key="smart photo"
        onClick={() =>
          window.open(`/package_tool/venues/${useFormProps.id}/smart_photos`)
        }>
        <Space>
          <FontAwesomeIcon icon={['fas', 'image']} />
          Smart Photo
        </Space>
      </Button>,
      <ListButton key="list" />,
      <RefreshButton key="refresh" />,
    ],
  }

  return (
    <Edit
      saveButtonProps={useFormProps.saveButtonProps}
      headerProps={pageHeaderProps}>
      <Modal {...modalProps} destroyOnClose>
        <Form
          {...addMemberFormProps}
          layout="vertical"
          onFinish={(values) => {
            if (isAddingNewUser) {
              return (
                addMemberFormProps.onFinish &&
                addMemberFormProps.onFinish({
                  ...values,
                  password: generatedPswd,
                })
              )
            }
            return (
              addMemberFormProps.onFinish &&
              addMemberFormProps.onFinish({
                ...values,
              })
            )
          }}>
          <Form.Item name="staff_user" label="Staff">
            <SelectWithDefault<IStaff>
              useSelectProps={{
                resource: STAFF_URL,
              }}
              allowClear
              renderOptions={(staffs) =>
                staffs?.map((staff) => (
                  <Select.Option key={staff.id} value={staff.id}>
                    {staff.first_name} {staff.last_name} ({staff.username})
                  </Select.Option>
                ))
              }
            />
          </Form.Item>
          <Button
            type="dashed"
            onClick={() => setIsAddingNewUser((prev) => !prev)}
            block>
            {isAddingNewUser ? 'Cancel Adding New User' : 'Add New User'}
          </Button>
          {isAddingNewUser && (
            <>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="first_name" label="First Name">
                <Input />
              </Form.Item>
              <Form.Item name="last_name" label="Last Name">
                <Input />
              </Form.Item>
              <AutoGeneratedPasswordInput
                label="Password"
                name="password"
                value={generatedPswd}
                onChange={setPassword}
                onClick={handlePassword}
              />
            </>
          )}
        </Form>
      </Modal>
      <VenueForm useFormProps={useFormProps} />
    </Edit>
  )
}
