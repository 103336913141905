import { DateField } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { useInvalidate, useUpdate } from '@refinedev/core'
import { Button, Form, InputNumber, Select, Space } from 'antd'
import { PriceField } from 'components/field'
import { DatePicker } from 'components/input'
import { ILead, ILeadService } from 'interfaces/crm'
import capitalize from 'lodash/capitalize'
import { useState } from 'react'
import { LEAD_SERVICE_URL, LEAD_URL } from 'urls'

export const BookingProbability = ({
  lead,
  is_lead_service,
  refetch,
  disabled,
}: {
  lead: ILead | ILeadService
  is_lead_service?: boolean
  refetch?: any
  disabled?: boolean
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [form] = Form.useForm()
  const invalidate = useInvalidate()
  const { mutate: patch } = useUpdate()
  const handleSubmit = (values: any) => {
    patch(
      {
        resource: is_lead_service ? LEAD_SERVICE_URL : LEAD_URL,
        id: lead.id,
        values,
      },
      {
        onSuccess: () => {
          setIsEditing(false)
          refetch && refetch()
          invalidate({
            resource: LEAD_URL,
            invalidates: ['list'],
          })
        },
      },
    )
  }
  return (
    <>
      {isEditing ? (
        <div style={{ width: 350 }}>
          <Form
            form={form}
            initialValues={lead}
            layout="inline"
            onFinish={(values) => {
              handleSubmit({
                booking_probability: values.booking_probability ?? null,
                expected_booking_date: values.expected_booking_date
                  ? typeof values.expected_booking_date == 'string'
                    ? values.expected_booking_date
                    : values.expected_booking_date.format('YYYY-MM-DD')
                  : null,
                estimated_value: values.estimated_value,
              })
            }}>
            <Form.Item name="booking_probability" noStyle>
              <Select
                dropdownMatchSelectWidth={false}
                allowClear
                options={[
                  { label: 'high', value: 'high' },
                  { label: 'medium', value: 'medium' },
                  { label: 'low', value: 'low' },
                ]}
              />
            </Form.Item>
            <Form.Item name="expected_booking_date" noStyle>
              <DatePicker
                format="YYYY-MM"
                allowClear
                picker="month"
                style={{ width: '100px' }}
              />
            </Form.Item>
            <Form.Item name="estimated_value" noStyle>
              <InputNumber addonAfter={<>Lakh</>} prefix="Rs" />
            </Form.Item>
          </Form>
        </div>
      ) : (
        <>
          {capitalize(lead.booking_probability)}
          {!!lead.expected_booking_date && is_lead_service && (
            <>
              {' '}
              by{' '}
              <DateField
                value={lead.expected_booking_date}
                format={'MMM, YYYY'}
              />
            </>
          )}
          {!!lead.estimated_value && (
            <> of {<PriceField value={lead.estimated_value ?? 0.0} />} L</>
          )}
        </>
      )}
      <Space>
        {isEditing && (
          <Button
            key="save"
            icon={<Icons.SaveOutlined />}
            size="small"
            onClick={() => form.submit()}
          />
        )}
        {is_lead_service && (
          <Button
            key="edit"
            icon={isEditing ? <Icons.CloseOutlined /> : <Icons.EditOutlined />}
            size="small"
            onClick={() => setIsEditing(!isEditing)}
            disabled={disabled}
          />
        )}
      </Space>
    </>
  )
}
