import { CreateButton, List, useTable } from "@refinedev/antd";
import { Table, Button, Space } from "antd";
import { useCan, useNavigation, useUpdate, useUpdateMany } from "@refinedev/core";
import { LogButton } from 'components/button';
import { TableSearchInput } from 'components/input/TableSearchInput';
import { IVendor } from 'interfaces/vendor';
import { useState } from 'react';
import { VENDOR_URL } from 'urls';
import { getSearchFormInitialValue } from 'utils/filter';

export const VendorList: React.FC = () => {
  const tProps = useTable<IVendor, any, { search: string }>({
    onSearch: ({ search }) => [
      {
        field: 'search',
        operator: 'eq',
        value: search,
      },
    ],

    syncWithLocation: true
  });
  const { edit } = useNavigation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const { mutate: updateMany } = useUpdateMany();
  const { mutate: update } = useUpdate();

  const { data: addVendorPerm } = useCan({
    resource: 'add_vendor',
    action: '',
  })

  const { data: deleteVendorPerm } = useCan({
    resource: 'delete_vendor',
    action: '',
  })

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: IVendor) => ({
      disabled: !record.is_active
    }),
  };

  const softDeleteMany = () => {
    updateMany({
      resource: VENDOR_URL,
      ids: selectedRowKeys,
      values: { is_active: false },
    });
    setSelectedRowKeys([]);
  };

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && deleteVendorPerm?.can && (
        <Button key="soft-delete" onClick={softDeleteMany} danger>
          Delete
        </Button>
      ),
      <TableSearchInput
        key="search"
        searchFormProps={tProps.searchFormProps}
        intialValues={getSearchFormInitialValue(tProps.filters)}
      />,
      <LogButton key="log" />,
      addVendorPerm?.can && (<CreateButton key="create" />),
    ],
  };

  const handleEnableVendor = (id: number) => {
    update({
      resource: VENDOR_URL,
      id,
      values: { is_active: true },
    });
  };

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        rowSelection={rowSelection}
        size="small"
        onRow={(record) => ({
          onClick: () => edit(VENDOR_URL, record.id),
        })}>
        <Table.Column dataIndex="name" title="Name" ellipsis />
        <Table.Column
          dataIndex="is_active"
          title="Status"
          render={(is_active: boolean) => (is_active ? "Active" : "Inactive")}
        />
        {addVendorPerm?.can && <Table.Column
          title="Actions"
          render={(record: IVendor) => (
            <Space>
              {!record.is_active && (
                <Button
                  type="link"
                  onClick={(event) => {
                    event.stopPropagation(); 
                    handleEnableVendor(record.id)}
                  }
                >
                  Enable
                </Button>
              )}
            </Space>
          )}
        />}
      </Table>
    </List>
  );
};
