import { useModalForm } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Badge,
  Button,
  Card,
  Divider,
  Form,
  Modal,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Select,
  Descriptions,
} from 'antd'

import { useDelete, useList, useModal, useOne } from '@refinedev/core'
import {
  IMeeting,
  MeetingAgenda,
  MeetingPeople,
  MeetingPlace,
} from 'interfaces/crm'
import { MEETING_URL, LEAD_SERVICE_URL } from 'urls'
import { DateFieldInput } from './BookingModal'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'

import weekday from "antd/node_modules/dayjs/plugin/weekday"
import localeData from "antd/node_modules/dayjs/plugin/localeData"

import { createOptionsFromEnum } from 'utils/common'

dayjs.extend(weekday)
dayjs.extend(localeData)

export const MeetingModal = ({
  lead_service_id,
  service,
  disabled
}: {
  lead_service_id: number
  service: string
  disabled?: boolean
}) => {
  const { show, close, visible } = useModal()
  const { mutate: destroy } = useDelete()

  const { data } = useList<IMeeting[]>({
    resource: MEETING_URL,

    filters: [
      {
        field: 'lead_service',
        operator: 'eq',
        value: lead_service_id,
      },
    ],
  })
  const {
    formProps,
    modalProps,
    show: addMeetingShow,
  } = useModalForm({
    resource: `${MEETING_URL}`,
    action: 'create',
    redirect: false,
    onMutationSuccess: () => refetch(),
  })

  const {
    data: meetings,
    isFetching: meetingsLoading,
    refetch,
  } = useOne<IMeeting[]>({
    resource: LEAD_SERVICE_URL,
    id: `${lead_service_id}/get_meetings`,
    queryOptions: { enabled: visible },
  })

  const MeetingDescription = ({
    label,
    value,
  }: {
    label: string
    value: string
  }) => {
    return (
      <Descriptions>
        <Descriptions.Item label={label}>{value}</Descriptions.Item>
      </Descriptions>
    )
  }

  return (
    <>
      <Badge count={data?.total} showZero size="small">
        <Button
          size="small"
          icon={<Icons.CalendarOutlined />}
          onClick={() => show()}
        />
      </Badge>
      <Modal
        onCancel={close}
        visible={visible}
        title={`${service} Meetings`}
        footer={null}
        destroyOnClose>
        {meetings?.data.map((meeting) => (
          <div key={meeting.id}>
            <Card
              size="small"
              title={
                <DateFieldInput id={meeting.id} date={meeting.date_time} />
              }
              extra={
                <Button
                  icon={<Icons.DeleteOutlined />}
                  danger
                  size="small"
                  disabled={disabled}
                  onClick={() =>
                    destroy(
                      {
                        resource: MEETING_URL,
                        id: meeting.id,
                      },
                      { onSuccess: () => refetch() },
                    )
                  }
                />
              }>
              <Row>
                <Col span={6}>
                  <MeetingDescription label={'Place'} value={meeting.place} />
                </Col>
                <Col span={6}>
                  <MeetingDescription label={'Agenda'} value={meeting.agenda} />
                </Col>
                <Col span={6}>
                  <MeetingDescription
                    label={'People in Meeting'}
                    value={meeting.people_present.join(' , ')}
                  />
                </Col>
              </Row>
            </Card>
            <Divider />
          </div>
        ))}
        {!disabled && <Button
          icon={<Icons.PlusOutlined />}
          type="primary"
          onClick={() => addMeetingShow()}>
          Meeting
        </Button>}
        <Modal {...modalProps} title="Add Meeting">
          <Form
            {...formProps}
            onFinish={(values) =>
              formProps.onFinish &&
              formProps.onFinish({
                ...values,
                lead_service: lead_service_id,
                date_time: dayjs((values as any).date_time).format(
                  'YYYY-MM-DD',
                ),
              })
            }>
            <Row gutter={10}>
              <Col>
                <Form.Item
                  name={'date_time'}
                  label="Date"
                  initialValue={dayjs()}>
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name={['place']}
                  label="Place"
                  rules={[{ required: false }]}>
                  <Select
                    style={{ width: '100%' }}
                    options={createOptionsFromEnum(MeetingPlace)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={['agenda']}
                  label="Agenda"
                  rules={[{ required: false }]}>
                  <Select
                    style={{ width: '100%' }}
                    options={createOptionsFromEnum(MeetingAgenda)}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={['people_present']}
                  label="People in meeting"
                  rules={[{ required: false }]}>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    options={createOptionsFromEnum(MeetingPeople)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Modal>
    </>
  )
}
