import { BaseKey } from '@refinedev/core'
import { Form, FormProps, InputNumber } from 'antd'
import { Input } from 'antd'
import MarkdownEditor from 'components/input/markdown/MarkdownEditor'

export const VenuePackageForm = (props: {
  formProps: FormProps
  venueId: BaseKey
}) => {
  const [form] = Form.useForm(props.formProps.form)

  return (
    <Form
      {...props.formProps}
      layout="vertical"
      onFinish={(values) => {
        props.formProps.onFinish!({ ...values, venue: props.venueId })
      }}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter the package name' }]}>
        <Input placeholder="Enter package name" />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <MarkdownEditor />
      </Form.Item>

      <Form.Item
        label="Order Id"
        name="order_id"
        rules={[{ required: true, message: 'Order Id amount is required' }]}>
        <InputNumber
          min={0}
          style={{ width: '100%' }}
          placeholder="Enter order id"
        />
      </Form.Item>

      <Form.Item
        label="Booking Amount"
        name="booking_amount"
        rules={[{ required: true, message: 'Booking amount is required' }]}>
        <InputNumber
          min={0}
          style={{ width: '100%' }}
          placeholder="Enter booking amount"
        />
      </Form.Item>

      <Form.Item
        label="Percentage Discount"
        name="percentage_discount"
        rules={[
          { required: true, message: 'Percentage discount is required' },
        ]}>
        <InputNumber
          min={0}
          max={100}
          style={{ width: '100%' }}
          placeholder="Enter percentage discount"
        />
      </Form.Item>

      <Form.Item
        label="Minimum Spend"
        name="min_spend"
        rules={[{ required: true, message: 'Minimum spend is required' }]}>
        <InputNumber
          min={0}
          style={{ width: '100%' }}
          placeholder="Enter minimum spend"
        />
      </Form.Item>

      <Form.Item label="Inclusion List" name="inclusion_list">
        <MarkdownEditor />
      </Form.Item>
    </Form>
  )
}
