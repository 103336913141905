import { useGetIdentity } from '@refinedev/core'
import { Spin } from 'antd'
import {
  FLUTTER_FIRST_NAME_KEY,
  FLUTTER_LAST_NAME_KEY,
  FLUTTER_REGION_KEY,
  FLUTTER_TOKEN_KEY,
  FLUTTER_USER_ID_KEY,
  REGION_KEY,
  TOKEN_KEY,
  USER_KEY,
} from 'authProvider'
import { IStaff } from 'interfaces/staff'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Location of flutter assets and scripts; also serviceWorkerVersion of the current flutter build
const src = `${process.env.PUBLIC_URL}/flutter/main.dart.js`
const firebaseSrc = `${process.env.PUBLIC_URL}/flutter/firebase-messaging-sw.js`
const serviceWorkerVersion = '4293178074'
const assetBase = `${process.env.PUBLIC_URL}/flutter/`

declare var _flutter: any

// Function to show Flutter Window in React
export const showFlutter = () => {
  const flutterElement = document.getElementById('flutter_view')

  flutterElement!.style.cssText = 'display:block;height:90vh;'
}

// Function to hide Flutter Window in React
export const hideFlutterApp = () => {
  const flutterElement = document.getElementById('flutter_view')

  flutterElement!.style.cssText = 'display:none;'
}

// returns the current url with query params
export const getUrlForFlutter = () =>
  `${window.location.pathname}${window.location.search}`

export const checkProdEnv = process.env.REACT_APP_ENV === 'prod'

export const FlutterWrapper: React.FC<{ children: any }> = ({ children }) => {
  // reference to the flutter element in the page
  const ref = useRef<HTMLDivElement>(null)
  // ref to the methods and states exposed by the Flutter JSInterop
  const flutterState = useRef<any>(null)
  // for navigation in flutter
  const navigate = useNavigate()
  const [flutterLoading, setFlutterLoading] = useState<boolean>(true)
  // latest User Data
  const { data: userData } = useGetIdentity<IStaff>()
  // updates the value of the flutter keys in localStorage
  const updateField = (
    flutterKey: string,
    webKey?: string,
    optionalValue?: any,
  ) => {
    const flutterValue = localStorage.getItem(flutterKey) ?? undefined
    const webValue = webKey
      ? localStorage.getItem(webKey) ?? undefined
      : undefined
    const compareValue = optionalValue ?? webValue
    if (checkProdEnv && flutterValue !== compareValue) {
      localStorage.setItem(flutterKey, JSON.stringify(compareValue))
    } else if (!flutterValue) {
      localStorage.setItem(flutterKey, JSON.stringify(compareValue))
    }
  }

  /**
   * Callback function for Flutter App, to push, replace, goBack, goForward in the react router history stack
   * Also, can open a url in new tab.
   *
   * @param {string}- A string of JSON Object of the following type:
   * ```js
   *    {
   *      "action": "push" | "replace" | "new-tab" | "back" | "forward",
   *      "url" : string // URL of the resource in React Router Context
   *    }
   * ```
   */
  const setUrlInReact = (t: string) => {
    const { action, url } = JSON.parse(t)
    switch (action) {
      case 'new-tab':
        window.open(url, '_blank')
        break

      case 'push':
      case 'replace':
        navigate(url, {
          replace: action === 'replace',
        })
        break

      case 'back':
        const canGoBack =
          !(window as any).navigation ||
          (window as any).navigation.canGoBack ||
          (window as any).navigation.canGoBack !== false
            ? true
            : false
        if (canGoBack) {
          navigate(-1)
        }
        break

      case 'forward':
        const canGoForward =
          !(window as any).navigation ||
          (window as any).navigation.canGoForward ||
          (window as any).navigation.canGoForward !== false
            ? true
            : false
        if (canGoForward) {
          navigate(1)
        }
        break

      default:
        break
    }
  }

  // Callback to update the flutterState and send event to flutter with current url
  const urlStateChangeCallback = (state: any) => {
    flutterState.current = state?.detail
    state?.detail?.setUrl(getUrlForFlutter())
    state?.detail?.onUrlChanged(setUrlInReact)
  }

  useEffect(() => {
    const target = ref.current
    // Init Flutter App
    const initFlutterApp = async () => {
      if ('serviceWorker' in navigator) {
        window.addEventListener('load', function () {
          navigator.serviceWorker.register(firebaseSrc)
        })
      }

      const engineInitializer = await new Promise<any>((resolve) => {
        _flutter.loader.loadEntrypoint({
          entrypointUrl: src,
          serviceWorker: {
            serviceWorkerVersion: serviceWorkerVersion,
          },
          onEntrypointLoaded: resolve,
        })
      })
      const appRunner = await engineInitializer?.initializeEngine({
        hostElement: document.getElementById('flutter_view'),
        assetBase: assetBase,
      })

      await appRunner?.runApp()
    }

    initFlutterApp()
    target?.addEventListener('url-state-manager', urlStateChangeCallback)

    updateField(FLUTTER_TOKEN_KEY, TOKEN_KEY)
    setFlutterLoading(false)

    return () => {
      target?.removeEventListener('url-state-manager', urlStateChangeCallback)
    }
  }, [])

  useEffect(() => {
    // send event to flutter with the current url and query params whenever the url changes
    // only if the flutterApp is loaded and flutterState ref has some current object
    if (!flutterLoading && flutterState.current) {
      flutterState?.current?.setUrl(getUrlForFlutter())
    }
  }, [window.location.pathname, flutterLoading, flutterState])

  useEffect(() => {
    const updateLocalStorage = () => {
      if (userData) {
        updateField(FLUTTER_REGION_KEY, REGION_KEY)
        updateField(FLUTTER_FIRST_NAME_KEY, undefined, userData.first_name)
        updateField(FLUTTER_LAST_NAME_KEY, undefined, userData.last_name)
        updateField(FLUTTER_USER_ID_KEY, USER_KEY, userData.id)
      }
    }
    updateLocalStorage()
  }, [userData])

  return (
    <>
      {children}

      <div
        ref={ref}
        id="flutter_view"
        style={{
          display: 'none',
        }}>
        {flutterLoading && (
          <div className="row h-center">
            <Spin />
          </div>
        )}
      </div>
    </>
  )
}
