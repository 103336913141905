import { Comment } from '@ant-design/compatible'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  List as AntdList,
  Badge,
  Button,
  Form,
  Modal,
  Space,
  Spin,
  Typography,
} from 'antd'
import { BaseKey, useCreate, useModal, useOne } from '@refinedev/core'
import { DateFieldFromNow } from 'components/field/DateFieldFromNow'

import parse from 'html-react-parser'
import { INotes } from 'interfaces/crm'
import { IStaff } from 'interfaces/staff'
import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { LEAD_SERVICE_URL, LEAD_URL } from 'urls'
import { isActualLoading } from 'utils/common'

interface EditorProps {
  onChange: (change: string) => void
  onSubmit: () => void
  submitting: boolean
  value: string
}

export const NotesInput = ({
  leadId,
  leadServiceId,
  notes: notesFromProps,
  withLabel = false,
  disabled
}: {
  leadId?: BaseKey
  leadServiceId?: BaseKey
  notes?: INotes[]
  withLabel?: boolean
  disabled?: boolean
}) => {
  const [value, setValue] = useState('')

  const { show, visible, close } = useModal()

  const {
    data: notes,
    refetch,
    isLoading: notesLoading,
    fetchStatus: notesFetchStatus,
  } = useOne<INotes[]>({
    resource: leadId ? LEAD_URL : LEAD_SERVICE_URL,
    id: `${leadId ?? leadServiceId}/get_notes`,
    queryOptions: {
      enabled: false,
    },
  })

  useEffect(() => {
    if (visible) refetch()
  }, [visible])

  const { mutate, isLoading } = useCreate()

  const finalNotes = notes?.data ?? notesFromProps

  return (
    <>
      <Space>
        <Badge
          count={
            isActualLoading(notesLoading, notesFetchStatus) ? (
              <Spin size="small" />
            ) : (
              (notesFromProps ?? notes?.data)?.length
            )
          }
          showZero
          size="small">
          <Button icon={<Icons.CommentOutlined />} onClick={show} size="small">
            {withLabel && 'Notes'}{' '}
          </Button>
        </Badge>
        {!!finalNotes?.length && (
          <span>
            Last note was{' '}
            <Typography.Text strong>
              <DateFieldFromNow
                value={finalNotes[finalNotes.length - 1]?.created_at}
              />
            </Typography.Text>
          </span>
        )}
      </Space>
      <Modal
        onCancel={close}
        open={visible}
        title={`Notes (${notes?.data.length})`}
        footer={null}>
        <AntdList
          className="comment-list"
          itemLayout="horizontal"
          dataSource={notes?.data}
          loading={notesLoading}
          renderItem={(item) => (
            <li>
              <Comment
                author={
                  !!item.user
                    ? `${(item.user as IStaff).first_name} ${
                        (item.user as IStaff).last_name
                      }`
                    : ''
                }
                content={parse(item.text || '')}
                datetime={<DateFieldFromNow value={item.created_at} />}
              />
            </li>
          )}
        />
        {!disabled && <Comment
          author={'New Note'}
          content={
            <>
              <Form.Item>
                <ReactQuill
                  theme="snow"
                  defaultValue={value ?? ' '}
                  value={value ?? ' '}
                  onChange={setValue}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={isLoading}
                  onClick={() => {
                    mutate(
                      {
                        resource: `${leadId ? LEAD_URL : LEAD_SERVICE_URL}/${
                          leadId ?? leadServiceId
                        }/add_note`,
                        values: { text: value },
                      },
                      {
                        onSuccess: () => {
                          setValue('')
                          refetch()
                        },
                      },
                    )
                  }}
                  disabled={!value}
                  type="primary">
                  Add Note
                </Button>
              </Form.Item>
            </>
          }
        />}
      </Modal>
    </>
  )
}

const Editor = ({ onChange, onSubmit, submitting, value }: EditorProps) => (
  <>
    <Form.Item label="Description" name="description" initialValue={''}>
      <ReactQuill theme="snow" value={value ?? ''} onChange={onChange} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        disabled={!value}
        type="primary">
        Add Note
      </Button>
    </Form.Item>
  </>
)
