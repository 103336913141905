// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, InputNumber, Popconfirm, Select, Space, Tooltip } from "antd";
import { useUpdate } from "@refinedev/core";
import { PriceField } from 'components/field'
import { Cities } from 'interfaces/crm'
import { useState } from 'react'
import { LEAD_URL } from 'urls'
import { createOptionsFromEnum } from 'utils/common'

export const BudgetInput = ({
  id,
  budget_amount,
  refetch,
  could_not_collect_budget,
  disabled
}: {
  id: number
  budget_amount: number
  refetch?: any
  could_not_collect_budget?: boolean
  disabled?: boolean
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [budget, setBudget] = useState(budget_amount)
  const { mutate: update, isLoading } = useUpdate()

  const handleSave = () => {
    update(
      {
        resource: LEAD_URL,
        id: id,
        values: {
          budget: budget,
          could_not_collect_budget: false,
        },
      },
      {
        onSuccess: () => {
          setIsEditing(false)
          refetch && refetch()
        },
      },
    )
  }

  const can_not_collect_budget_confirmation = () => {
    update(
      {
        resource: LEAD_URL,
        id: id,
        values: { could_not_collect_budget: true },
      },
      {
        onSuccess: () => {
          setIsEditing(false)
          refetch && refetch()
        },
      },
    )
  }

  return (
    <Space>
      {!isEditing && (
        <>
          <PriceField value={budget ?? 0.0} /> L
        </>
      )}
      {isEditing && (
        <>
          <InputNumber
            value={budget}
            max={100}
            onChange={(value: any) => setBudget(value)}
            addonAfter={<>Lakh</>}
          />
          {(!budget || budget === 0) && !could_not_collect_budget && (
            <Popconfirm
              trigger="hover"
              title="Could not find budget?"
              description="Are you sure, you could not find budget?"
              onConfirm={can_not_collect_budget_confirmation}
              okText="Yes"
              cancelText="No">
              <Button
                danger
                type="primary"
                shape="circle"
                icon={<Icons.FrownFilled />}
                size="middle"
              />
            </Popconfirm>
          )}
        </>
      )}
      {isEditing ? (
        <Button
          key="save"
          icon={<Icons.SaveOutlined />}
          loading={isLoading}
          size="small"
          onClick={() => handleSave()}
        />
      ) : (
        <Button
          key="edit"
          icon={<Icons.EditOutlined />}
          size="small"
          onClick={() => setIsEditing(true)}
          disabled={disabled}
        />
      )}
      {could_not_collect_budget && (
        <Tooltip title="Lead in progress, without budget info!!">
          <Icons.WarningOutlined style={{ color: 'red' }} />
        </Tooltip>
      )}
    </Space>
  )
}

export const CityInput = ({
  id,
  city_name,
  disabled
}: {
  id: number
  city_name: string
  disabled?: boolean
}) => {
  // CityInput can take only one city: either from provided option or a custom input.

  const [isEditing, setIsEditing] = useState(false)
  const [city, setCity] = useState(city_name ? [city_name] : [])
  const [valid, setValid] = useState(true)
  const { mutate: update } = useUpdate()

  const check_valid = (values: string[]) => {
    if (values.length > 1) {
      setValid(false)
    } else if (values.length == 0) {
      setValid(false)
    } else {
      setValid(true)
    }
    setCity(values)
  }

  const handleSave = () => {
    update(
      {
        resource: LEAD_URL,
        id: id,
        values: {
          city: city[0],
        },
      },
      {
        onSuccess: () => {
          setIsEditing(false)
        },
      },
    )
  }

  return (
    <>
      {!isEditing && city[0]}
      {isEditing && (
        <Select
          mode="tags"
          style={{ width: 120 }}
          options={createOptionsFromEnum(Cities)}
          status={!valid ? 'error' : undefined}
          onChange={(values: string[]) => {
            check_valid(values)
          }}
          value={city}
        />
      )}
      {isEditing ? (
        <Button
          key="save"
          icon={<Icons.SaveOutlined />}
          size="small"
          disabled={!valid && true}
          onClick={() => handleSave()}
        />
      ) : (
        <Button
          key="edit"
          icon={<Icons.EditOutlined />}
          size="small"
          onClick={() => setIsEditing(true)}
          disabled={disabled}
        />
      )}
    </>
  )
}
