// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  FormProps,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tabs,
} from 'antd'

import { BaseKey, Option, useCan } from '@refinedev/core'
import { DatePicker } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { ILead } from 'interfaces/crm'
import { ICustomer } from 'interfaces/customer'
import { IStaff } from 'interfaces/staff'
import { CUSTOMER_URL, SHORTLIST_PACKAGE_URL, STAFF_URL } from 'urls'
import { BookingModal } from './components/BookingModal'
import { ChangeSelect } from './components/ChangeSelect'
import { EventsModal } from './components/EventsModal'
import { NotesInput } from './components/NotesInput'
import { ServicesModal } from './components/ServicesModal'
import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'

export const LeadForm = ({
  formProps,
  id,
  data,
}: {
  formProps: FormProps
  id?: BaseKey
  data?: ILead
}) => {
  const customer = Form.useWatch('customer', formProps.form)
  const project = Form.useWatch('project', formProps.form)
  const _package = Form.useWatch('package', formProps.form)

  const getDropDownValues = () => {
    const tempOptions = []
    let initialDate = dayjs()
    for (let i = 0; i < 12; i++) {
      tempOptions.push({
        value: initialDate.format('MMMM YYYY'),
        label: initialDate.format('MMMM YYYY'),
      })
      initialDate = initialDate.add(1, 'month')
    }
    return tempOptions
  }

  const { data: updateLeadPerm } = useCan({
    resource: 'change_lead',
    action: '',
  })

  return (
    <Form
      disabled={!updateLeadPerm?.can}
      {...formProps}
      layout="vertical"
      onFinish={(values: any) => {
        if (typeof values.expected_booking_date !== 'string')
          values = {
            ...values,
            expected_booking_date:
              values.expected_booking_date?.format('YYYY-MM-DD'),
          }
        formProps.onFinish && formProps.onFinish(values)
      }}>
      <Tabs>
        <Tabs.TabPane tab="Basic" key={'basic'} forceRender>
          <Card size="small">
            {!!id && (
              <div className="row h-center">
                <Space size="large">
                  <Space direction="vertical" align="center">
                    <>Status</>
                    <ChangeSelect
                      leadId={id}
                      type="status"
                      change={data?.status}
                    />
                  </Space>
                  <Space direction="vertical" align="center">
                    Stage
                    <ChangeSelect
                      leadId={id}
                      type="stage"
                      change={data?.stage}
                    />
                  </Space>
                </Space>
              </div>
            )}
            <Divider />
            {id && (
              <>
                <Space>
                  <NotesInput leadId={id} withLabel notes={data?.notes} />
                  <ServicesModal leadId={id} withLabel />{' '}
                  <BookingModal leadId={id} withLabel />
                  <EventsModal leadId={id} withLabel />
                </Space>
                <Divider />
              </>
            )}
            <Form.Item name="pre_sales" label="Pre Sales">
              <SelectWithDefault<IStaff>
                useSelectProps={{
                  resource: STAFF_URL,
                }}
                renderOptions={(staffs) =>
                  staffs?.map((staff) => (
                    <Select.Option key={staff.id} value={staff.id}>
                      {staff.first_name} {staff.last_name} ({staff.username})
                    </Select.Option>
                  ))
                }
              />
            </Form.Item>
            <Form.Item name="relationship_manager" label="RM">
              <SelectWithDefault<IStaff>
                useSelectProps={{
                  resource: STAFF_URL,
                }}
                renderOptions={(staffs) =>
                  staffs?.map((staff) => (
                    <Select.Option key={staff.id} value={staff.id}>
                      {staff.first_name} {staff.last_name} ({staff.username})
                    </Select.Option>
                  ))
                }
              />
            </Form.Item>
            <Form.Item name="consultant" label="Consultant">
              <SelectWithDefault<IStaff>
                useSelectProps={{
                  resource: STAFF_URL,
                }}
                renderOptions={(staffs) =>
                  staffs?.map((staff) => (
                    <Select.Option key={staff.id} value={staff.id}>
                      {staff.first_name} {staff.last_name} ({staff.username})
                    </Select.Option>
                  ))
                }
              />
            </Form.Item>
            <Form.Item name="call_attempt" label="Call Attempt">
              <InputNumber />
            </Form.Item>
            <Form.Item name="call_attempt_date_time" label="Call Attempt Date">
              <DatePicker
                showTime={{ use12Hours: true }}
                format="YYYY-MM-DD hh:mm a"
              />
            </Form.Item>
          </Card>
          <Divider />
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input />
          </Form.Item>
          <Form.Item name="pincode" label="Pincode">
            <Input />
          </Form.Item>
          <Form.Item name="event_horizon" label="Event Horizon">
            <Select options={getDropDownValues() as Option[]} />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Profile" key={'profile'} forceRender>
          <Form.Item name="POC_full_name" label="POC Name">
            <Input />
          </Form.Item>
          <Form.Item name="bride_name" label="Bride Name">
            <Input />
          </Form.Item>
          <Form.Item name="groom_name" label="Groom Name">
            <Input />
          </Form.Item>
          <Form.Item name="POC_relation" label="POC Relation">
            <Input />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Price and Package" key="price" forceRender>
          <Space>
            <Form.Item name="estimated_value" label="Estimated Value (₹)">
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="expected_booking_date"
              label="Expected Booking Date">
              <DatePicker format="YYYY-MM-DD" mode="date" />
            </Form.Item>
            <Form.Item name="booking_probability" label="Booking Probability">
              <Select
                options={[
                  { label: 'high', value: 'high' },
                  { label: 'medium', value: 'medium' },
                  { label: 'low', value: 'low' },
                ]}
              />
            </Form.Item>
          </Space>
          <Form.Item name="budget" label="Budget (₹)">
            <InputNumber />
          </Form.Item>
          <Form.Item name="customer" label="Customer">
            <SelectWithDefault<ICustomer>
              useSelectProps={{
                resource: CUSTOMER_URL,
                optionLabel: 'first_name',
              }}
              renderOptions={(customers) =>
                customers?.map((customer) => (
                  <Select.Option key={String(customer.id)} value={customer.id}>
                    {customer.first_name} {customer.last_name} (
                    {customer.username})
                  </Select.Option>
                ))
              }
            />
          </Form.Item>

          {(!!data?.customer || !!customer) && (
            <Form.Item name="project" label="Project">
              <SelectWithDefault
                useSelectProps={{
                  resource: `${CUSTOMER_URL}/${
                    data?.customer || customer
                  }/events`,
                  optionLabel: 'name',
                  queryOptions: {
                    enabled: !!data?.customer || !!customer,
                  },
                }}
              />
            </Form.Item>
          )}
          {(!!data?.project || !!project) && (
            <Row>
              <Col span={23}>
                <Form.Item name="package" label="Package">
                  <SelectWithDefault
                    style={{ width: '100%' }}
                    useSelectProps={{
                      resource: SHORTLIST_PACKAGE_URL,
                      optionLabel: 'name',
                      filters: [
                        { field: 'event', operator: 'eq', value: project },
                      ],
                      queryOptions: {
                        enabled: !!data?.project || !!project,
                      },
                    }}
                  />
                </Form.Item>
              </Col>
              {(!!data?.package || !!_package) && (
                <Col>
                  <Button
                    icon={<Icons.ShareAltOutlined />}
                    onClick={() =>
                      window.open(`/${SHORTLIST_PACKAGE_URL}/edit/${_package}`)
                    }
                  />
                </Col>
              )}
            </Row>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Marketing" key="marketing" forceRender>
          <Form.Item name="source" label="Source">
            <Input />
          </Form.Item>
          <Form.Item name="city" label="City">
            <Input />
          </Form.Item>
          <Form.Item name="campaign" label="Campaign">
            <Input />
          </Form.Item>
          <Form.Item name="ad_set" label="Ad Set">
            <Input />
          </Form.Item>
          <Form.Item name="term" label="Term">
            <Input />
          </Form.Item>
          <Form.Item name="medium" label="Medium">
            <Input />
          </Form.Item>
          <Form.Item name="content" label="Content">
            <Input />
          </Form.Item>
          <Form.Item name="gclid" label="GCLID">
            <Input />
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  )
}
