// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Col, Form, InputNumber, Modal, Row, Spin, Switch } from "antd";
import { BaseKey, useCreate, useModal, useOne } from "@refinedev/core";
import { DatePicker } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { ILeadEvent } from 'interfaces/crm'
import { LEAD_URL, SECTION_URL } from 'urls'

export const EventsModal = ({
  leadId,
  withLabel = false,
  disabled
}: {
  leadId: BaseKey
  withLabel?: boolean
  disabled?: boolean
}) => {
  const { show, close, visible } = useModal()

  const [form] = Form.useForm()

  const { data: leadEvents, isFetching: leadEventsLoading } = useOne<
    ILeadEvent[]
  >({
    resource: LEAD_URL,
    id: `${leadId}/get_events`,
    queryOptions: {
      enabled: visible,
    },
  })


  const { mutate, isLoading } = useCreate()

  return (
    <>
      <Button icon={<Icons.SelectOutlined />} size="small" onClick={show}>
        {withLabel && 'Events'}
      </Button>
      <Modal
        onCancel={() => {
          form.resetFields()
          close()
        }}
        visible={visible}
        title="Events"
        okButtonProps={{ loading: isLoading }}
        onOk={() => {
          if (!disabled){
            form.submit()
          } else {
            form.resetFields()
            close()
          }
        }}>
        {leadEventsLoading ? (
          <div className="row h-center v-center">
            <Spin />
          </div>
        ) : (
          <Form
            form={form}
            preserve={false}
            disabled={disabled}
            onFinish={(values) => {
              mutate(
                {
                  resource: `${LEAD_URL}/${leadId}/add_event`,
                  values: values['events'].filter(
                    (value: any) => !!value.event,
                  ),
                },
                { onSuccess: () => close() },
              )
            }}>
            <Form.List
              name="events"
              initialValue={
                leadEvents?.data.length
                  ? leadEvents?.data.map((event) => ({
                    ...event,
                  }))
                  : [{}]
              }>
              {(fields, { add }) => (
                <>
                  {
                    fields.map((field) => {
                      return (
                        <Row key={field.name} gutter={16}>
                          <Col span={8}>
                            <Form.Item {...field} name={[field.name, 'event']} >
                              <SelectWithDefault
                                useSelectProps={{
                                  resource: SECTION_URL,
                                  optionLabel: 'name',
                                }}
                                size="small"
                                placeholder="Event"
                                dropdownMatchSelectWidth={false}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              name={[field.name, 'date_not_decided']}
                              valuePropName="checked"
                              label="Date not decided">
                              <Switch />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item {...field} name={[field.name, 'date_time']}>
                              <DatePicker
                                showTime
                                allowClear
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </Col>

                        </Row>
                      )
                    })}
                  <Form.Item>
                    {!disabled && <Button type="dashed" onClick={() => add()} block>
                      Add Event
                    </Button>}
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        )}
      </Modal>
    </>
  )
}
