import ReactMarkdown from 'react-markdown'

export const VenuePackageDetails = ({
  packageDetails,
}: {
  packageDetails: any
}) => {
  return (
    <div
      style={{
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
      }}>
      <h2
        style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
        {packageDetails.name}
      </h2>
      <div style={{ fontSize: '16px', color: '#555', marginBottom: '20px' }}>
        <ReactMarkdown>{packageDetails.description}</ReactMarkdown>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ marginBottom: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>Booking Amount: </span>
          <span>₹{packageDetails.booking_amount}</span>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>Percentage Discount: </span>
          <span>{packageDetails.percentage_discount}%</span>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>Minimum Spend: </span>
          <span>₹{packageDetails.min_spend}</span>
        </div>
      </div>
      <div>
        <h3
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            marginBottom: '10px',
          }}>
          Inclusion List
        </h3>
        <div style={{ fontSize: '16px', color: '#555' }}>
          <ReactMarkdown>{packageDetails.inclusion_list}</ReactMarkdown>
        </div>
      </div>
    </div>
  )
}
